<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon, DropdownSelect, defaultStringStartsWithFilter } from '@pids/shared-component';
  import type { LineFilter as LineFilterModel } from '$generated/service/cache-api';
  import ScheduleSelect from 'src/components/shared/ScheduleSelect.svelte';
  import type { ScheduleId } from 'src/model/ScheduleId';
  import { type LineFilterCriteria } from './lineService';
  import { QuickFilterType } from 'src/components/pages/routingService';

  export let filter: LineFilterCriteria;

  export let filterData: LineFilterModel;

  export let onScheduleChange: (s: ScheduleId) => void;

  let agencies: string[];
  let types: string[];

  $: agencies = filterData.agencies;
  $: types = filterData.types;
</script>

<div class="columns">
  <div class="column">
    <label for="name-search">
      {$_('line.filter.label.name')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="name-search"
        class="input"
        placeholder={$_('line.filter.placeholder.name')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="id-search">
      {$_('line.filter.label.id')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="id-search"
        class="input"
        placeholder={$_('line.filter.placeholder.id')}
        bind:value={filter.id}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    {#if types}
      <label for="type-filter">
        {$_('line.filter.label.type')}
      </label>
      <div class="control">
        <DropdownSelect
          bind:value={filter.type}
          items={types}
          filter={defaultStringStartsWithFilter}
          placeholder={$_('line.filter.placeholder.type')}
          empty={$_('line.filter.label.type.empty')}
          readonly={Boolean(filter.type)} />
      </div>
    {/if}
  </div>

  <div class="column">
    {#if agencies}
      <label for="agency-filter">
        {$_('line.filter.label.agency')}
      </label>
      <div class="control">
        <DropdownSelect
          bind:value={filter.agency}
          id="agency-filter"
          items={agencies}
          filter={defaultStringStartsWithFilter}
          placeholder={$_('line.filter.placeholder.agency')}
          empty={$_('line.filter.label.agency.empty')}
          readonly={Boolean(filter.agency)} />
      </div>
    {/if}
  </div>

  <div class="column">
    <label for="schedule-select">
      {$_('line.filter.schedule.label')}
    </label>
    <div class="control">
      <ScheduleSelect
        value={filter.schedule}
        id="schedule-select"
        on:change={e => onScheduleChange(e.detail)}
        type={QuickFilterType.Line} />
    </div>
  </div>
</div>
