<script lang="ts">
  export let title: string | undefined = undefined;
  export let isOperational: boolean | undefined = undefined;
  export let dayNotInMonth: boolean | undefined = undefined;
  export let isInvalidInterval: boolean | undefined = undefined;
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .default-style-properties {
    --trip-size: 0.875rem;
  }

  .calendar-day {
    border-radius: 0.125rem;
    padding: 0;
    margin: 0;
    height: var(--trip-size);
    width: var(--trip-size);
    background-color: $primary-light;
    color: $white;
    text-align: center;
    vertical-align: middle;

    justify-content: center;
    align-items: center;
    display: flex;
  }

  .calendar-day.is-operational {
    background-color: $primary;
  }

  .calendar-day.not-in-month {
    visibility: hidden;
  }

  .calendar-day.invalid-interval {
    background-color: $white-dark;
  }
</style>

<div
  class="calendar-day default-style-properties"
  {title}
  class:not-in-month={dayNotInMonth}
  class:invalid-interval={isInvalidInterval}
  class:is-operational={isOperational}>
  <slot />
</div>
