import { get } from 'svelte/store';
import { CacheApi, Configuration as CacheApiConfiguration, ConfigurationDataApi } from '$generated/service/cache-api';
import { config } from 'src/config/static';
import { user } from 'src/user/userStore';
import { handleHttpError } from 'src/api/httpErrorHandling';
import { LicenseApi, Configuration as LicenseApiConfiguration } from 'src/generated/service/license-api';
import { FeedManagementApi, Configuration as FeedApiConfiguration } from '$generated/service/feed-api';
const getAuthTokenValue = () => {
    const authenticatedUser = get(user);
    const token = authenticatedUser === null || authenticatedUser === void 0 ? void 0 : authenticatedUser.token;
    return token ? 'Bearer ' + token : 'undefined';
};
class HubApiMiddleware {
    pre(context) {
        const authTokenValue = getAuthTokenValue();
        return Promise.resolve({
            url: context.url,
            init: Object.assign(Object.assign({}, context.init), { headers: Object.assign(Object.assign({}, context.init.headers), { Authorization: authTokenValue }) })
        });
    }
    post(context) {
        return handleHttpError(context.response);
    }
}
export let cacheApi;
export let configurationDataApi;
export let licenseApi;
export let feedManagementApi;
config.subscribe(conf => {
    const cacheApiConfiguration = new CacheApiConfiguration({
        basePath: conf.hubCacheApiBasePath,
        middleware: [new HubApiMiddleware()]
    });
    const licenseApiConfiguration = new LicenseApiConfiguration({
        basePath: `${conf.hubBaseUrl}${conf.licenseBasePath}`,
        middleware: [new HubApiMiddleware()]
    });
    const feedApiConfig = new FeedApiConfiguration({
        basePath: `${conf.hubBaseUrl}`,
        middleware: [new HubApiMiddleware()]
    });
    cacheApi = new CacheApi(cacheApiConfiguration);
    configurationDataApi = new ConfigurationDataApi(cacheApiConfiguration);
    licenseApi = new LicenseApi(licenseApiConfiguration);
    feedManagementApi = new FeedManagementApi(feedApiConfig);
});
