<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { format } from 'date-fns';
  import { ActionIcon, Table, TableCol } from '@pids/shared-component';
  import type { LoadingState } from '@pids/shared-component';
  import { type Feed, FeedStatusLevelEnum } from '$generated/service/feed-api';

  const dateTimeFormat = 'dd.MM.yy (HH:mm:ss)';

  export let feeds: Feed[] = [];
  export let loading: LoadingState = undefined;
  export let onConfigClick: (feed: Feed) => void;
  export let onActionClick: (feedId: string, action: 'start' | 'stop') => Promise<void>;

  const levelClass: Record<FeedStatusLevelEnum, string> = {
    [FeedStatusLevelEnum.Info]: 'status-info',
    [FeedStatusLevelEnum.Warn]: 'status-warn',
    [FeedStatusLevelEnum.Error]: 'status-danger'
  };

  const triggerStartAction = (feed: string) => {
    return onActionClick(feed, 'start');
  };

  const triggerStopAction = (feed: string) => {
    return onActionClick(feed, 'stop');
  };

  const formatDateTime = (dt: Date): string => {
    return format(dt, dateTimeFormat);
  };
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .indicator {
    height: 14px;
    width: 14px;

    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .indicator-active {
    background-color: $green;
  }

  .indicator-inactive {
    background-color: $red;
  }

  .status-info {
    color: $info;
  }

  .status-warn {
    color: $warning;
  }

  .status-error {
    color: $danger;
  }

  .feed-actions {
    display: flex;
    gap: 0.5rem;
  }
</style>

<Table rows={feeds} {loading} let:row emptyText={$_('table.empty.default')} loadingText={$_('table.loading.default')}>
  <TableCol {row} header={$_('feed-management.column.name')} width="20%">
    {#if row}
      <p class:has-text-grey={!row.running}>{row.name}</p>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('feed-management.column.active')} width="2%">
    {#if row}
      <span class="indicator" class:indicator-active={row.running} class:indicator-inactive={!row.running} />
    {/if}
  </TableCol>

  <TableCol {row} header={$_('feed-management.column.start-time')} width="10%">
    {#if row && row.startTime && row.running}
      {formatDateTime(row.startTime)}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('feed-management.column.last-run')} width="10%">
    {#if row && row.lastSuccessfulRun}
      {formatDateTime(row.lastSuccessfulRun)}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('feed-management.column.next-run')} width="10%">
    {#if row && row.nextRun}
      {formatDateTime(row.nextRun)}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('feed-management.column.status')} width="4%">
    {#if row && row.status}
      <span class={levelClass[row.status.level]}><strong>{row.status.level}</strong></span>
    {/if}
  </TableCol>

  <TableCol {row}>
    {#if row && row.status}
      <span>{row.status.message}</span>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('feed-management.column.actions')} width="9%">
    {#if row}
      <div class="feed-actions">
        <ActionIcon icon="tune" on:click={() => onConfigClick(row)} />
        {#if row.running}
          <ActionIcon icon="stop-circle" disabled={!row.running} on:click={() => triggerStopAction(row.id)} />
        {:else}
          <ActionIcon icon="play-circle-outline" disabled={row.running} on:click={() => triggerStartAction(row.id)} />
        {/if}
      </div>
    {/if}
  </TableCol>
</Table>
