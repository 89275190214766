<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { FieldList, ModalDialog } from '@pids/shared-component';
  import type { Role } from 'src/user/roles';
  import type { User } from 'src/user/user';

  export let show = false;
  export let user: User;

  const rolesToString = (roles: Role[]): string => {
    if (!roles || roles.length == 0) {
      return '';
    }

    return roles
      .filter(role => role === role.toUpperCase())
      .map(role => role.replaceAll(/[_-]/g, ' ').toLowerCase().trim())
      .join(', ');
  };

  const getUserFields = (user: User): Record<string, string> => {
    return {
      [$_('form.auth.username')]: user.username,
      [$_('label.organization')]: user.organization,
      [$_('label.user.roles')]: rolesToString(user.roles),
      [$_('label.language')]: user.locale
    };
  };
</script>

{#if user}
  <ModalDialog
    title={$_('profile.title')}
    disableBorder={true}
    contentPadding={false}
    bind:active={show}
    okText={$_('button.ok')}
    cancelText={$_('button.cancel')}
    width={420}>
    <FieldList fields={getUserFields(user)} />
  </ModalDialog>
{:else}
  <p>{$_('profile.text.unauthenticated')}</p>
{/if}
