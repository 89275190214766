/**
 * Contains all the fields that can be sorted by in the table
 */
export var MarketingTextSort;
(function (MarketingTextSort) {
    MarketingTextSort["TextDe"] = "textDe";
    MarketingTextSort["TextFr"] = "textFr";
    MarketingTextSort["TextIt"] = "textIt";
    MarketingTextSort["TextEn"] = "textEn";
    MarketingTextSort["Agency"] = "agency";
})(MarketingTextSort || (MarketingTextSort = {}));
