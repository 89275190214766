/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import { DisruptionMessageFromJSON, DisruptionMessageFromJSONTyped, DisruptionMessageToJSON, } from './DisruptionMessage';
import { ResponseMetadataFromJSON, ResponseMetadataFromJSONTyped, ResponseMetadataToJSON, } from './ResponseMetadata';
/**
 * Check if a given object implements the DisruptionMessages interface.
 */
export function instanceOfDisruptionMessages(value) {
    if (!('metadata' in value) || value['metadata'] === undefined)
        return false;
    if (!('results' in value) || value['results'] === undefined)
        return false;
    return true;
}
export function DisruptionMessagesFromJSON(json) {
    return DisruptionMessagesFromJSONTyped(json, false);
}
export function DisruptionMessagesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'metadata': ResponseMetadataFromJSON(json['metadata']),
        'results': (json['results'].map(DisruptionMessageFromJSON)),
    };
}
export function DisruptionMessagesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'metadata': ResponseMetadataToJSON(value['metadata']),
        'results': (value['results'].map(DisruptionMessageToJSON)),
    };
}
