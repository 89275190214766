<script lang="ts">
  import type { MarketingText } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { MarketingTextSort } from 'src/components/pages/marketing/marketingTextSort';
  import { Table, TableCol } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';

  export let resultsList: MarketingText[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: MarketingTextSort };
  }>();

  const dispatchSortEvent = (sort: MarketingTextSort) => {
    dispatch('sort', { sort });
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('marketing-text.column.text-de.header-label')}
    value="textDe"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.TextDe)} />

  <TableCol
    {row}
    header={$_('marketing-text.column.text-fr.header-label')}
    value="textFr"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.TextFr)} />

  <TableCol
    {row}
    header={$_('marketing-text.column.text-it.header-label')}
    value="textIt"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.TextIt)} />

  <TableCol
    {row}
    header={$_('marketing-text.column.text-en.header-label')}
    value="textDe"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.TextDe)} />

  <TableCol {row} header={$_('marketing-text.column.trains.header-label')} width="33%">
    {#if row?.trains}
      {row.trains.join(', ')}
    {/if}
  </TableCol>

  <TableCol
    {row}
    header={$_('marketing-text.column.agency.header-label')}
    value="agency"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.Agency)} />
</Table>
