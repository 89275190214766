<script lang="ts">
  import type { Text } from '$generated/service/cache-api';

  export let label: string;
  export let texts: Array<Text> | undefined;
</script>

<style>
  tr td:first-child {
    font-weight: 600;
  }
</style>

{#if texts && texts.length > 0}
  {#each texts as text}
    <tr>
      <td>{label} [{text.language}] [{text.size}]</td>
      <td>
        {#if text.text && text.text.length > 0}
          {#each text.text as line}
            <p>{line}</p>
          {/each}
        {/if}
      </td>
    </tr>
  {/each}
{/if}
