<script lang="ts">
  import { format, getYear, isAfter, isBefore, isSameDay, isSameMonth, isToday, parse } from 'date-fns';
  import CalendarDaySquare from 'src/components/pages/trips/details/CalendarDaySquare.svelte';
  import CalendarTodayMark from 'src/components/pages/trips/details/CalendarTodayMark.svelte';
  import type { ExpandedMonth, ExpandedYear } from 'src/util/calendarUtils';

  export let operationalDays: Date[];

  export let calendarDates: ExpandedYear[];

  const getMonthName = (date: Date) => {
    return format(date, 'LLL');
  };

  const isInvalidInterval = (day: Date, month: ExpandedMonth) => {
    return (
      (month.validUntil && !isSameDay(day, month.validUntil) && isAfter(day, month.validUntil)) ||
      (month.validFrom && !isSameDay(day, month.validFrom) && isBefore(day, month.validFrom))
    );
  };

  const getOperationalDayKey = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
  };

  const parseDate = (operationalDay: string | Date) => {
    if (typeof operationalDay === 'string') {
      return parse(operationalDay, 'yyyy-MM-dd', new Date());
    }

    return operationalDay;
  };

  const extractOperationalDays = (operationalDays: Date[]) => {
    return new Set(
      operationalDays.map(operationalDay => {
        const parsedOperationalDay = parseDate(operationalDay);
        return getOperationalDayKey(parsedOperationalDay);
      })
    );
  };

  $: calendarDays = extractOperationalDays(operationalDays);
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .default-style-props {
    --month-spacing: 0.5rem;
    --day-gap: 2px;
  }

  .left-align.right-align {
    text-align: right;
  }

  .left-align {
    text-align: left;
  }

  .calendar-week {
    display: flex;
    flex-direction: column;
    gap: var(--day-gap);
  }

  .calendar-weeks {
    display: flex;
    gap: var(--day-gap);
  }

  .calendar-month {
    display: flex;
    flex-direction: column;
  }

  .calendar-months {
    display: flex;
    gap: var(--month-spacing);
  }

  .calendar-year {
    display: flex;
    flex-direction: column;
    gap: 0.65rem;
  }

  .calendar-years {
    display: flex;
    gap: var(--month-spacing);
    justify-content: center;
  }

  .day.current-month {
    position: relative;
    justify-content: center;
    display: flex;
  }

  .tooltip {
    position: absolute;
    background-color: $high-emphasis;
    color: $white;
    top: -275%;
    width: max-content;
    display: flex;
    box-shadow: 0 2px 4px rgba(158, 158, 158, 0.3);
    border: 1px solid black;
    border-radius: 0.3125rem;
    padding: 0.25rem 0.5rem;
    z-index: 10;

    transition-delay: 10ms;
    transition-property: visibility;
  }

  .day:not(:hover) > .tooltip {
    visibility: hidden;
  }
</style>

<div class="default-style-props">
  {#if calendarDays && calendarDates}
    <div class="calendar-years">
      {#each calendarDates as year, i}
        {@const isMultiYear = calendarDates.length > 1}

        <div class="calendar-year">
          {#if isMultiYear}
            <div class:right-align={i === 0} class="left-align">
              {getYear(year.current)}
            </div>
          {/if}

          <div class="calendar-months">
            {#each year.months as month}
              <div class="calendar-month">
                <div class="calendar-weeks">
                  {#each month.weeks as week}
                    <div class="calendar-week">
                      {#each week as day}
                        {@const isDayInMonth = isSameMonth(month.current, day)}
                        {@const isOperational = calendarDays.has(getOperationalDayKey(day))}
                        <div class="day" class:current-month={isDayInMonth}>
                          <CalendarDaySquare
                            dayNotInMonth={!isDayInMonth}
                            isInvalidInterval={isInvalidInterval(day, month)}
                            {isOperational}>
                            {#if isToday(day) && isDayInMonth}
                              <CalendarTodayMark />
                            {/if}
                          </CalendarDaySquare>
                          <div class="tooltip">
                            {getOperationalDayKey(day)}
                          </div>
                        </div>
                      {/each}
                    </div>
                  {/each}
                </div>
                <div style:text-align="center">
                  {getMonthName(month.current)}
                </div>
              </div>
            {/each}
          </div>
        </div>
        {#if i === 0 && isMultiYear} | {/if}
      {/each}
    </div>
  {/if}
</div>
