<script lang="ts">
  import type { DisruptionCausesFilterCriteria } from 'src/components/pages/disruptionCausesVdv/disruptionCausesService';
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon, DropdownSelect } from '@pids/shared-component';
  import { DisruptionCauseCategory, DisruptionCauseGroup } from '$generated/service/cache-api';

  const categories: string[] = Object.values(DisruptionCauseCategory);
  const groups: string[] = Object.values(DisruptionCauseGroup);

  export let filter: DisruptionCausesFilterCriteria;
</script>

<div class="columns">
  <div class="column">
    <label for="query-search" style="visibility: hidden">
      {$_('disruption-causes.filter.query.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="query-search"
        class="input"
        placeholder={$_('disruption-causes.filter.query.placeholder')}
        bind:value={filter.query}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for="category-filter">
      {$_('disruption-causes.filter.category.label')}
    </label>
    <div class="control">
      <DropdownSelect
        id="category-filter"
        items={categories}
        bind:value={filter.category}
        placeholder={$_('disruption-causes.filter.category.placeholder')}
        readonly={Boolean(filter.category)}
        showEmptyText={false}
        let:item>
        {item.toUpperCase()}
      </DropdownSelect>
    </div>
  </div>
  <div class="column">
    <label for="cause-search">
      {$_('disruption-causes.filter.cause.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="cause-search"
        class="input"
        placeholder={$_('disruption-causes.filter.cause.placeholder')}
        bind:value={filter.cause}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for="group-filter">
      {$_('disruption-causes.filter.group.label')}
    </label>
    <div class="control">
      <DropdownSelect
        id="group-filter"
        items={groups}
        bind:value={filter.group}
        placeholder={$_('disruption-causes.filter.group.placeholder')}
        showEmptyText={false}
        readonly={!filter.group}
        let:item>
        {item.toLowerCase()}
      </DropdownSelect>
    </div>
  </div>
</div>
