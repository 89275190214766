<script lang="ts">
  import { isPlainObject } from 'src/util/objectUtils';
  import { FieldListLayout } from '@pids/shared-component';

  export let title: string | undefined = undefined;
  export let config: object;

  $: configValues = Object.entries(config).filter(([, v]) => !isPlainObject(v));
</script>

<style>
  .config-name {
    display: flex;
    gap: 0.5rem;
  }
</style>

<div>
  {#if title}
    <p
      style:padding-inline="1rem"
      class="configurer-section-title menu-label is-size-7 has-text-weight-bold has-text-black">
      {title}
    </p>
  {/if}

  <FieldListLayout fields={configValues} --grid-template-columns="5fr 4fr">
    <svelte:fragment slot="left" let:field>
      {@const [key] = field}
      <div class="config-name">
        <span style:font-weight="normal">{key}</span>
      </div>
    </svelte:fragment>
    <svelte:fragment slot="right" let:field>
      {@const [, value] = field}
      {value}
    </svelte:fragment>
  </FieldListLayout>
</div>
