/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const AccessibilityPlatformAccess = {
    AccessWithoutAssistance: 'PLATFORM_ACCESS_WITHOUT_ASSISTANCE',
    AccessWithAssistance: 'PLATFORM_ACCESS_WITH_ASSISTANCE',
    AccessWithAssistanceWhenNotified: 'PLATFORM_ACCESS_WITH_ASSISTANCE_WHEN_NOTIFIED',
    NotWheelchairAccessible: 'PLATFORM_NOT_WHEELCHAIR_ACCESSIBLE',
    AccessWithAdvanceNotification: 'PLATFORM_ACCESS_WITH_ADVANCE_NOTIFICATION',
    WithNoInformation: 'PLATFORM_WITH_NO_INFORMATION'
};
export function instanceOfAccessibilityPlatformAccess(value) {
    for (const key in AccessibilityPlatformAccess) {
        if (Object.prototype.hasOwnProperty.call(AccessibilityPlatformAccess, key)) {
            if (AccessibilityPlatformAccess[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function AccessibilityPlatformAccessFromJSON(json) {
    return AccessibilityPlatformAccessFromJSONTyped(json, false);
}
export function AccessibilityPlatformAccessFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function AccessibilityPlatformAccessToJSON(value) {
    return value;
}
