import { wrapPaginated } from 'src/api/query/fetchResult';
import { configurationDataApi } from 'src/api/apiClients';
import { applyStringParam } from 'src/api/paramUtils';
export const fetchServicePoints = (filter, pagination, options) => {
    const params = createFilterParams(filter, pagination);
    const func = () => configurationDataApi.findServicePoints(params, options);
    return wrapPaginated(func);
};
const createFilterParams = (filterCriteria, pagination) => {
    var _a;
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    applyStringParam({
        value: (_a = filterCriteria.countryCode) === null || _a === void 0 ? void 0 : _a.toString(),
        target: request,
        eqField: 'countryCodeEq',
        likeField: 'countryCodeLike'
    });
    applyStringParam({
        value: filterCriteria.sloid,
        target: request,
        eqField: 'sloidEq',
        likeField: 'sloidLike'
    });
    applyStringParam({
        value: filterCriteria.name,
        target: request,
        eqField: 'nameEq',
        likeField: 'nameLike'
    });
    applyStringParam({
        value: filterCriteria.abbreviation,
        target: request,
        eqField: 'abbreviationEq',
        likeField: 'abbreviationLike'
    });
    return request;
};
