import { wrapPaginated } from 'src/api/query/fetchResult';
import { cacheApi } from 'src/api/apiClients';
import { applyStringParam } from 'src/api/paramUtils';
import { isNotBlank } from 'src/util/stringUtils';
export const fetchAgencies = (filter, pagination, options) => {
    const params = createFilterParams(filter, pagination);
    const func = () => cacheApi.findAgencies(params, options);
    return wrapPaginated(func);
};
const createFilterParams = (filterCriteria, pagination) => {
    const request = {};
    applyStringParam({ value: filterCriteria.name, target: request, eqField: 'nameEq', likeField: 'nameLike' });
    if (filterCriteria.schedule && isNotBlank(filterCriteria.schedule.id)) {
        request.schedules = [filterCriteria.schedule.id];
    }
    request.page = pagination.page;
    request.size = pagination.size;
    request.sort = pagination.sort;
    return request;
};
