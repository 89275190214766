<script lang="ts">
  export let height = '1em';
</script>

<svg {height} viewBox="0 0 59 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2 0C0.89543 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H57C58.1046 20 59 19.1046 59 18V2C59 0.895431 58.1046 0 57 0H2Z"
    fill="#F27E00" />
  <path
    d="M14.153 4.16968V6.31201H7.97992V8.7876H13.6373V10.7792H7.97992V13.6277H14.2641V15.77H5.44879V4.16968H14.153Z"
    fill="black" />
  <path
    d="M25.3112 4.16968L21.455 15.77H18.5748L14.7741 4.16968H17.4243L20.0348 12.858L22.6849 4.16968H25.3112Z"
    fill="black" />

  <slot />
</svg>
