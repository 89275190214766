// permissions available on the backend
// need to be in sync with the backend
export var Permission;
(function (Permission) {
    Permission["SCHEDULE_READ"] = "SCHEDULE_READ";
    Permission["AGENCY_READ"] = "AGENCY_READ";
    Permission["LINE_READ"] = "LINE_READ";
    Permission["STATION_READ"] = "STATION_READ";
    Permission["TRIP_READ"] = "TRIP_READ";
    Permission["MESSAGE_READ"] = "MESSAGE_READ";
    Permission["MARKETING_READ"] = "MARKETING_READ";
    Permission["LINE_CATEGORY_READ"] = "LINE_CATEGORY_READ";
    Permission["LINE_COLOR_READ"] = "LINE_COLOR_READ";
    Permission["LINE_TYPE_READ"] = "LINE_TYPE_READ";
    Permission["SERVICE_POINT_READ"] = "SERVICE_POINT_READ";
    Permission["DISRUPTION_CAUSE_READ"] = "DISRUPTION_CAUSE_READ";
    Permission["FEED_MANAGEMENT"] = "FEED_MANAGEMENT";
})(Permission || (Permission = {}));
