<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { type LoadingStateStore, PageLayout, ModalDialog, Button } from '@pids/shared-component';
  import type { Feed } from '$generated/service/feed-api';
  import { createQuery } from 'src/api/query';
  import { fetchFeeds, startFeed, stopFeed } from './feedService';
  import FeedTable from './FeedTable.svelte';
  import { onDestroy } from 'svelte';
  import FeedConfigTableContainer from 'src/components/pages/feedManagement/FeedConfigTableContainer.svelte';

  const query = createQuery([], signal => fetchFeeds({ signal }), true);

  const actionSwitch = {
    start: startFeed,
    stop: stopFeed
  };

  let feeds: Feed[] = [];
  $: feeds = $query.isError ? [] : $query.results;

  let selectedFeed: Feed | undefined = undefined;

  let loading: LoadingStateStore;
  $: loading = query.loading;

  const handleAction = async (feedId: string, action: 'start' | 'stop'): Promise<void> => {
    const func = actionSwitch[action];

    const response = await func(feedId);
    if (!response.isError) {
      await query.next();
    }
  };

  const displayConfig = (feed: Feed): void => {
    selectedFeed = feed;
  };

  const closeConfigDialog = () => {
    selectedFeed = undefined;
  };

  const reloadData = async () => {
    await query.next();
  };

  const getModalTitle = (feed: Feed): string => {
    return `${feed.name} (${feed.id})` ?? '';
  };

  onDestroy(() => {
    query.abort();
  });
</script>

<PageLayout title={$_('routes.group.admin.feed-management')}>
  <svelte:fragment slot="actions">
    <Button content={$_('feed-management.actions.refresh')} primary={true} icon="refresh" on:click={reloadData} />
  </svelte:fragment>
  <svelte:fragment slot="content">
    {#if $query.isError}
      <p class="has-text-danger">Failed to load feeds</p>
    {:else}
      <FeedTable {feeds} loading={$loading} onActionClick={handleAction} onConfigClick={displayConfig} />
    {/if}
  </svelte:fragment>
</PageLayout>

{#if selectedFeed}
  <ModalDialog
    title={getModalTitle(selectedFeed)}
    active={selectedFeed !== undefined}
    okText={$_('button.ok')}
    okHandler={closeConfigDialog}
    width={800}>
    {#if selectedFeed}
      <FeedConfigTableContainer config={selectedFeed.config} />
    {/if}
  </ModalDialog>
{/if}
