import { __awaiter } from "tslib";
import { getConfig } from 'src/config/static';
/**
 * Fetches the stations that match the given query.
 *
 * @param query the search query
 * @param scheduleType the schedule type
 */
export const fetchStations = (query, scheduleType, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { hubDataApiBasePath } = getConfig();
    const url = new URL(`${hubDataApiBasePath}/station/search`);
    url.searchParams.set('name', query);
    url.searchParams.set('scheduleType', scheduleType);
    const response = yield fetch(url, options);
    if (response.ok) {
        const body = yield response.json();
        return body.stations.map(s => s.station);
    }
    else {
        return [];
    }
});
