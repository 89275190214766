<script lang="ts">
  import CalendarDaySquare from 'src/components/pages/trips/details/CalendarDaySquare.svelte';
  import CalendarTodayMark from 'src/components/pages/trips/details/CalendarTodayMark.svelte';
  import TripCalendarOperationalDays from 'src/components/pages/trips/details/TripCalendarOperationalDays.svelte';
  import type { Calendar, DateRange } from 'src/generated/service/cache-api';
  import { expandYears, type ExpandedYear } from 'src/util/calendarUtils';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  export let calendar: Calendar;
  export let validityPeriod: DateRange;

  let calendarDates: ExpandedYear[] | undefined;

  onMount(() => {
    calendarDates = expandCalendar(validityPeriod);
  });

  const expandCalendar = (period: DateRange) => {
    const from = period.start && parseDate(period.start);
    const to = period.end && parseDate(period.end);

    if (!from || !to) return;
    return expandYears(from, to);
  };

  const parseDate = (operationalDay: string | Date) => {
    if (typeof operationalDay === 'string') {
      return new Date(Date.parse(operationalDay));
    }

    return operationalDay;
  };
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .operational-days {
    display: grid;
    gap: 2rem;
  }

  .calendar {
    display: grid;
    gap: 1.25rem;
  }

  .calendar-legends {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  }

  .calendar-legend {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
</style>

<div class="operational-days">
  {#if calendarDates}
    <div class="calendar">
      {#if calendar.operationalDays}
        <TripCalendarOperationalDays operationalDays={calendar.operationalDays} {calendarDates} />
      {/if}
    </div>
  {/if}
  <div class="calendar-legends">
    <div class="calendar-legend">
      <CalendarTodayMark /> <span>{$_('trip.details.calendar.legend.today-mark')}</span>
    </div>
    <div class="calendar-legend">
      <CalendarDaySquare isOperational={true} /> <span>{$_('trip.details.calendar.legend.trip')}</span>
    </div>
    <div class="calendar-legend">
      <CalendarDaySquare /> <span>{$_('trip.details.calendar.legend.unscheduled')}</span>
    </div>
  </div>
</div>
