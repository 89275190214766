/**
 * Creates a sort function with the given defaults.
 *
 * @param initialField the initial field to sort by
 * @param initialOrder the initial sort order
 */
export const sortBy = (initialField, initialOrder = 'asc') => {
    const sort = {
        field: initialField,
        order: initialOrder
    };
    return (field) => {
        if (field !== sort.field) {
            sort.field = field;
            sort.order = 'asc';
        }
        else {
            if (sort.order === 'asc') {
                sort.order = 'desc';
            }
            else {
                sort.order = 'asc';
            }
        }
        return `${sort.field},${sort.order}`;
    };
};
