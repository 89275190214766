<script lang="ts">
  import type { LineCategory } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { LineCategoriesSort } from 'src/components/pages/linecategories/lineCategoriesSort';
  import { Table, TableCol } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';

  export let resultsList: LineCategory[];
  export let loading: LoadingState;

  const dispatch = createEventDispatcher<{
    sort: { sort: LineCategoriesSort };
  }>();

  const dispatchSortEvent = (sort: LineCategoriesSort) => {
    dispatch('sort', { sort });
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('line-categories.column.transport-type.header-label')}
    value="transportType"
    width="12%"
    sortable
    onHeaderClick={() => dispatchSortEvent(LineCategoriesSort.TransportType)} />

  <TableCol
    {row}
    header={$_('line-categories.column.category.header-label')}
    value="category"
    width="10%"
    sortable
    onHeaderClick={() => dispatchSortEvent(LineCategoriesSort.Category)} />

  <TableCol
    {row}
    header={$_('line-categories.column.transport-group.header-label')}
    value="transportGroup"
    width="14%"
    sortable
    onHeaderClick={() => dispatchSortEvent(LineCategoriesSort.TransportGroup)} />

  <TableCol {row} header={$_('line-categories.column.text-de.header-label')} value="textDe" />

  <TableCol {row} header={$_('line-categories.column.text-fr.header-label')} value="textFr" />

  <TableCol {row} header={$_('line-categories.column.text-it.header-label')} value="textIt" />

  <TableCol {row} header={$_('line-categories.column.text-en.header-label')} value="textEn" />
</Table>
