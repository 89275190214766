<script lang="ts">
  import { onDestroy } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { NumberedPagination, PageLayout, notificationService, type LoadingStateStore } from '@pids/shared-component';
  import type { LineTypeEntry, Pagination } from '$generated/service/cache-api';
  import { createQuery } from 'src/api/query/query';
  import { type PaginationParams, sortBy, type SortFunction } from 'src/api/query';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { SvelteEvent } from 'src/model/event';
  import LineTypeTable from './LineTypeTable.svelte';
  import { fetchLineTypeMapping } from './lineTypeService';
  import { LineTypeSort } from './lineTypeSort';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${LineTypeSort.Key},asc`
  };

  let paginationParams: PaginationParams = initialPagination;

  const sortFunction: SortFunction = sortBy(LineTypeSort.Key);

  const resultsQuery = createQuery([], signal => fetchLineTypeMapping(paginationParams, { signal }), true);

  let results: LineTypeEntry[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;
  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onDestroy(() => resultsQuery.abort());

  const loadData = async () => {
    await resultsQuery.next();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: LineTypeSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };
</script>

<PageLayout title={$_('line-type.page-title')}>
  <svelte:fragment slot="content">
    <LineTypeTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
