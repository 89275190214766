<script lang="ts">
  import { onMount, tick } from 'svelte';
  import { writable } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { querystring, replace } from 'svelte-spa-router';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import type { LoadingStateStore } from '@pids/shared-component';
  import type { SvelteEvent } from 'src/model/event';
  import type { ScheduleId } from 'src/model/ScheduleId';
  import type { Pagination, Trip, TripFilter as TripFilterModel } from '$generated/service/cache-api';
  import type { PaginationParams, SortFunction } from 'src/api/query';
  import { createQuery, sortBy, paginationParamName } from 'src/api/query';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import { toTripsByStationLink } from 'src/components/pages/routingService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import { type TripCriteria, TripSort, tripParamName } from 'src/components/pages/trips/types';
  import { fetchTripFilterData, fetchTrips } from 'src/components/pages/trips/tripService';
  import TripTable from 'src/components/pages/trips/TripTable.svelte';
  import TripFilter from 'src/components/pages/trips/TripFilter.svelte';
  import StationSelect from './StationSelect.svelte';
  import { stationStore } from './stationStore';

  const INITIAL_SORT = TripSort.StartTime;

  const sortFunction: SortFunction = sortBy(INITIAL_SORT);

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${INITIAL_SORT},asc`
  };

  let paginationParams: PaginationParams = initialPagination;

  let stationId: string | undefined;
  let tripCriteria = writable<TripCriteria>({});

  const resultsQuery = createQuery(
    [],
    () => fetchTrips({ ...$tripCriteria, stationId: stationId }, paginationParams),
    false
  );

  let firstLoad = true;

  // the page is ready when the query parameters are processed
  let ready = false;

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = stationId === undefined;

  let results: Trip[];
  $: results = $resultsQuery.isError ? [] : $resultsQuery.results;

  let pagination: Pagination | undefined;
  $: pagination = $resultsQuery.isError ? undefined : $resultsQuery.pagination;

  let filterData: TripFilterModel = {
    agencies: []
  };

  // keeps the latest loaded schedule to avoid unnecessary fetching on "clear filters"
  let filterDataSchedule: string | undefined = '_';

  let loading: LoadingStateStore;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(async () => {
    handleQueryString($querystring);

    await tick();

    ready = true;

    const unsub = tripCriteria.subscribe(() => {
      if (!firstLoad) {
        resetPageAndSort();
      } else {
        firstLoad = false;
      }
      void loadData();
    });

    await loadFilterData($tripCriteria.schedule?.id);

    return () => unsub();
  });

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    stationId = params.get(tripParamName.station) ?? undefined;
    paginationParams = initializePaginationFromQueryParams(params);
    $tripCriteria = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): TripCriteria => {
    const getParam = (name: string) => params.get(name) ?? undefined;

    return {
      ...$tripCriteria,
      id: getParam(tripParamName.id) ?? undefined,
      line: getParam(tripParamName.line) ?? undefined,
      destination: getParam(tripParamName.destination) ?? undefined,
      agency: getParam(tripParamName.agency) ?? undefined,
      schedule: {
        id: getParam(tripParamName.schedule) ?? ''
      },
      stationId: getParam(tripParamName.station)
    };
  };

  const initializePaginationFromQueryParams = (params: URLSearchParams): PaginationParams => {
    return {
      page: params.get(paginationParamName.page) ?? paginationParams.page,
      size: params.get(paginationParamName.size) ?? paginationParams.size,
      sort: params.get(paginationParamName.sort) ?? paginationParams.sort
    } as PaginationParams;
  };

  const loadData = async () => {
    updateUrlParameters();
    if (stationId) {
      await Promise.allSettled([resultsQuery.next(), loadFilterData($tripCriteria.schedule?.id)]);
    }
  };

  const updateUrlParameters = () => {
    const fullUrl = toTripsByStationLink(
      { ...$tripCriteria, schedule: $tripCriteria.schedule?.id, stationId: stationId },
      paginationParams
    );
    replace(fullUrl);
  };

  const loadFilterData = async (scheduleId?: string | undefined) => {
    if (filterDataSchedule === scheduleId) {
      return;
    }

    filterDataSchedule = scheduleId;
    filterData = await fetchTripFilterData(scheduleId);
  };

  const handleSortChange = (e: SvelteEvent<{ sort: TripSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };

    void loadData();
  };

  const clearFilters = () => {
    stationId = undefined;
    $tripCriteria = {};
    $stationStore = undefined;
  };

  // NOTE: whenever the schedule changes, agencies must be cleared and reloaded
  // for the newly selected schedule
  const handleScheduleChange = (s: ScheduleId) => {
    loadFilterData(s?.id);

    $tripCriteria = {
      ...$tripCriteria,
      schedule: s,
      agency: undefined
    };
  };
</script>

<PageLayout title={$_('routes.group.core.trip-by-station')}>
  <svelte:fragment slot="criteria">
    {#if ready}
      <StationSelect bind:stationId />
    {/if}
    {#if stationId}
      <TripFilter bind:tripCriteria={$tripCriteria} {filterData} onScheduleChange={handleScheduleChange} />
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    {#if stationId}
      <TripTable {results} loading={$loading} on:sort={handleSortChange} {stationId} />
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if stationId && !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
