<script lang="ts">
  import type { PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { Pagination, Agency } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import type { AgencyFilterCriteria } from 'src/components/pages/agency/agencyService';
  import { _ } from 'svelte-i18n';
  import { writable } from 'svelte/store';
  import { onMount } from 'svelte';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { AgencySort } from 'src/components/pages/agency/agencySort';
  import { createQuery } from 'src/api/query/query';
  import { sortBy } from 'src/api/query/sort';
  import { fetchAgencies } from 'src/components/pages/agency/agencyService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import AgencyTable from 'src/components/pages/agency/AgencyTable.svelte';
  import AgencyFilter from 'src/components/pages/agency/AgencyFilter.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${AgencySort.Name},asc`
  };

  const sortFunction: SortFunction = sortBy(AgencySort.Name);
  let paginationParams: PaginationParams = initialPagination;

  let results: Agency[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  let filter = writable<AgencyFilterCriteria>({});

  const resultsQuery = createQuery([], signal => fetchAgencies($filter, paginationParams, { signal }), false);

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    clearFilters();

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    await resultsQuery.next();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: AgencySort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const filtersAreEmpty = (filter: AgencyFilterCriteria): boolean => {
    return !filter.name && !filter.schedule?.id;
  };

  const clearFilters = () => {
    $filter = {};
  };
</script>

<PageLayout title={$_('agency.page.title')}>
  <svelte:fragment slot="criteria">
    <AgencyFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <AgencyTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
