<script lang="ts">
  export let line: string;
  export let background = '#FFFFFF';
  export let foreground = '#000000';
</script>

<style>
  .line {
    background-color: var(--background-color);
    color: var(--foreground-color);
    padding: 0.03rem 0.875rem;
    border-radius: 2.5rem;
    text-align: center;
    min-width: 3.5rem;
    display: inline-block;
    font-weight: bold;
  }
</style>

<span style:--background-color={background} style:--foreground-color={foreground} class="line">
  {line}
</span>
