<script lang="ts">
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { ServicePointFilterCriteria } from 'src/components/pages/servicePoint/servicePointService';

  export let filter: ServicePointFilterCriteria;
</script>

<div class="columns">
  <div class="column">
    <label for="text-search">
      {$_('service-point.filter.label.sloid')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('service-point.filter.placeholder.sloid')}
        bind:value={filter.sloid}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="text-search">
      {$_('service-point.filter.label.country-code')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('service-point.filter.placeholder.country-code')}
        bind:value={filter.countryCode}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="text-search">
      {$_('service-point.filter.label.name')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('service-point.filter.placeholder.name')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="text-search">
      {$_('service-point.filter.label.name.short')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('service-point.filter.placeholder.name.short')}
        bind:value={filter.abbreviation}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
</div>
