<script lang="ts">
  import { Table, TableCol } from '@pids/shared-component';
  import type { Line } from 'src/generated/service/cache-api';
  import { LineSort } from 'src/components/pages/line/lineSort';
  import ColoredLine from 'src/components/shared/ColoredLine.svelte';
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { QuickFilterType, toTripsLink, type Link } from 'src/components/pages/routingService';
  import QuickFilters from 'src/components/shared/QuickFilters.svelte';
  import { quoted } from 'src/util/stringUtils';
  import type { LoadingState } from '@pids/shared-component';
  import LineIcon from 'src/components/pages/line/icons/LineIcon.svelte';
  import { getLineIcon } from 'src/components/pages/line/icons/lineHelpers';

  export let resultsList: Line[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: LineSort };
  }>();

  const dispatchSortEvent = (sort: LineSort) => {
    dispatch('sort', { sort });
  };

  const getTripsLink = (line: string, schedule: string, agency: string): Link => {
    return {
      type: QuickFilterType.Trip,
      url: toTripsLink({
        line: quoted(line),
        agency: quoted(agency),
        schedule
      })
    };
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol {row} header={$_('line.column.label.name')} sortable onHeaderClick={() => dispatchSortEvent(LineSort.Name)}>
    {#if row}
      <LineIcon height="1.25rem" type={getLineIcon(row.type)} number={row.number ?? ''}>
        <ColoredLine line={row.name} background={row.color.background} foreground={row.color.foreground} />
      </LineIcon>
    {/if}
  </TableCol>

  <TableCol {row} value="id" header={$_('line.column.label.id')} />

  <TableCol
    {row}
    value="type"
    header={$_('line.column.label.type')}
    sortable
    onHeaderClick={() => dispatchSortEvent(LineSort.Type)} />

  <TableCol
    {row}
    value="number"
    header={$_('line.column.label.number')}
    sortable
    onHeaderClick={() => dispatchSortEvent(LineSort.Number)} />

  <TableCol
    {row}
    value="agency"
    header={$_('line.column.label.agency')}
    sortable
    onHeaderClick={() => dispatchSortEvent(LineSort.Agency)}>
    {#if row?.agency}
      {row.agency}
    {/if}
  </TableCol>

  <TableCol {row} value="schedule" header={$_('line.column.schedule.label')} className="w20">
    {#if row}
      {row.schedule}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('label.column.quick-filter')}>
    {#if row}
      <QuickFilters links={[getTripsLink(row.name, row.schedule, row.agency)]} />
    {/if}
  </TableCol>
</Table>
