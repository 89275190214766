import { ROLE_PERMISSION_MAPPING } from 'src/user/rolePermissions';
import { OrganizationRole, Role } from './roles';
/**
 * Checks whether the user has at least one of the provided permissions assigned.
 *
 * @param user the user
 * @param permissions the permissions to check for
 */
export const hasAnyPermission = (user, permissions) => {
    if (!user.roles) {
        return false;
    }
    return permissions.some(p => hasPermission(user, p));
};
/**
 * Checks whether the user has the provided permission assigned.
 *
 * @param user the user
 * @param permission the permission to check for
 */
export const hasPermission = (user, permission) => {
    var _a, _b;
    // users without any roles should not be allowed to anything
    if (!((_a = user.roles) === null || _a === void 0 ? void 0 : _a.length)) {
        return false;
    }
    if ((_b = user.organizationRoles) === null || _b === void 0 ? void 0 : _b.length) {
        return user.organizationRoles.some(organizationRole => user.roles.some(role => hasPermissionInternal(role, organizationRole, permission)));
    }
    return user.roles.some(role => hasPermissionInternal(role, OrganizationRole.NONE, permission));
};
const hasPermissionInternal = (role, organizationRole, permission) => {
    // admin should be allowed to any UI part
    if (role && role === Role.NCA_ADMIN) {
        return true;
    }
    const permissionsForRole = ROLE_PERMISSION_MAPPING[organizationRole][role];
    return permissionsForRole && permissionsForRole.includes(permission);
};
