<script lang="ts">
  export let height = '1.5rem';
</script>

<svg {height} viewBox="0 0 59 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2 0C0.89543 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H57C58.1046 20 59 19.1046 59 18V2C59 0.895431 58.1046 0 57 0H2Z"
    fill="#EB0000" />
  <path d="M9.22072 4.19995H13.2768L8.25878 15.8H4.18579L9.22072 4.19995Z" fill="white" />
  <path
    d="M15.9483 4.19995H30.3432L28.8526 7.64542H18.5724L16.5311 12.3545H26.8158L25.3259 15.8H10.9309L15.9483 4.19995Z"
    fill="white" />

  <slot />
</svg>
