<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ActionIcon, Table, TableCol } from '@pids/shared-component';
  import type { DisruptionMessage } from '$generated/service/cache-api';
  import { fromMessagePage, MessageSort } from './types';
  import { formatInterval } from './messageUtils';
  import type { LoadingState } from '@pids/shared-component';
  import { selectedMessage } from './messageStore';
  import { toMessageDetailsLink } from 'src/components/pages/routingService';

  export let results: DisruptionMessage[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: MessageSort };
  }>();

  const handleHeaderClick = (col: MessageSort) => {
    return () => dispatch('sort', { sort: col });
  };

  const navigateToDetails = (message: DisruptionMessage | undefined) => {
    $fromMessagePage = true;
    selectedMessage.set(message);
  };

  const toLink = (message: DisruptionMessage | undefined) => {
    if (!message) {
      return '#';
    }

    return toMessageDetailsLink({
      id: message.id,
      schedule: message.schedule
    });
  };
</script>

<Table rows={results} {loading} let:row emptyText={$_('table.empty.default')} loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('message.column.id.label')}
    value="id"
    sortable
    onHeaderClick={handleHeaderClick(MessageSort.Id)} />

  <TableCol
    {row}
    header={$_('message.column.intervals.label')}
    sortable
    onHeaderClick={handleHeaderClick(MessageSort.Interval)}>
    {#if row && row.activeIntervals}
      {#each row.activeIntervals as interval}
        <p>{formatInterval(interval)}</p>
      {/each}
    {/if}
  </TableCol>

  <TableCol {row} value="cause" header={$_('message.column.cause.label')} />

  <TableCol {row} value="schedule" header={$_('message.column.schedule.label')} />

  <TableCol {row}>
    <ActionIcon href={toLink(row)} on:click={() => navigateToDetails(row)} icon="search" />
  </TableCol>
</Table>
