<script lang="ts">
  import { PageLayout, notificationService } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { Schedule } from '$generated/service/cache-api';
  import { createQuery } from 'src/api/query/query';
  import { fetchSchedules } from 'src/components/pages/schedule/scheduleService';
  import SchedulesTable from 'src/components/pages/schedule/ScheduleTable.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import { onDestroy } from 'svelte';

  let results: Schedule[] = [];
  let loading: LoadingStateStore;

  const resultsQuery = createQuery([], signal => fetchSchedules({ signal }), true);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onDestroy(() => resultsQuery.abort());
</script>

<PageLayout title={$_('schedule.page.title')}>
  <svelte:fragment slot="content">
    <SchedulesTable loading={$loading} resultsList={results} />
  </svelte:fragment>
</PageLayout>
