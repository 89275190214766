/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import { DependencyFromJSON, DependencyFromJSONTyped, DependencyToJSON, } from './Dependency';
/**
 * Check if a given object implements the Dependencies interface.
 */
export function instanceOfDependencies(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
export function DependenciesFromJSON(json) {
    return DependenciesFromJSONTyped(json, false);
}
export function DependenciesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(DependencyFromJSON)),
    };
}
export function DependenciesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(DependencyToJSON)),
    };
}
