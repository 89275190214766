/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
/**
 * Check if a given object implements the LineColorLine interface.
 */
export function instanceOfLineColorLine(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    return true;
}
export function LineColorLineFromJSON(json) {
    return LineColorLineFromJSONTyped(json, false);
}
export function LineColorLineFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'type': json['type'],
    };
}
export function LineColorLineToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'type': value['type'],
    };
}
