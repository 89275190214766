<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Table, TableCol } from '@pids/shared-component';
  import { AgencySort } from 'src/components/pages/agency/agencySort';
  import type { Agency } from 'src/generated/service/cache-api';
  import { toTripsLink, type Link, QuickFilterType, toLinesLink } from 'src/components/pages/routingService';
  import QuickFilters from 'src/components/shared/QuickFilters.svelte';
  import { quoted } from 'src/util/stringUtils';
  import type { LoadingState } from '@pids/shared-component';

  export let resultsList: Agency[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: AgencySort };
  }>();

  const dispatchSortEvent = (sort: AgencySort) => {
    dispatch('sort', { sort });
  };

  const getTripsLink = (agency: string | undefined, schedule: string | undefined): Link => {
    return {
      type: QuickFilterType.Trip,
      url: toTripsLink({
        agency: quoted(agency),
        schedule: schedule
      })
    };
  };

  const getLinesLink = (agency: string | undefined, schedule: string | undefined): Link => {
    return {
      type: QuickFilterType.Line,
      url: toLinesLink({
        agency: agency,
        schedule: schedule
      })
    };
  };

  const hasAgency = (agency: Agency) => {
    return Boolean(agency.schedule.agencies?.includes(agency.id) || agency.schedule.agencies?.includes(agency.name));
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol {row} header={$_('agency.column.label.id')} value="id" className="w20" />

  <TableCol
    {row}
    value="name"
    header={$_('agency.column.label.name')}
    sortable
    onHeaderClick={() => dispatchSortEvent(AgencySort.Name)} />

  <TableCol
    {row}
    value="fullName"
    header={$_('agency.column.label.full-name')}
    sortable
    onHeaderClick={() => dispatchSortEvent(AgencySort.FullName)} />

  <TableCol {row} value="schedule" header={$_('agency.column.schedule.label')} className="w20">
    {#if row}
      {row.schedule.id}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('agency.column.label.quick-filters')}>
    {#if row && hasAgency(row)}
      <QuickFilters links={[getLinesLink(row?.name, row?.schedule.id), getTripsLink(row?.name, row?.schedule.id)]} />
    {/if}
  </TableCol>
</Table>
