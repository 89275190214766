<script lang="ts">
  import { Table, TableCol } from '@pids/shared-component';
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';
  import type { ServicePoint } from 'src/generated/service/cache-api/models/ServicePoint';
  import { ServicePointSort } from 'src/components/pages/servicePoint/servicePointSort';
  import { formatValidityPeriod } from 'src/util/dateTimeUtils';

  export let resultsList: ServicePoint[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: ServicePointSort };
  }>();

  const dispatchSortEvent = (sort: ServicePointSort) => {
    dispatch('sort', { sort });
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    value="sloid"
    header={$_('service-point.column.label.sloid')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.Sloid)} />

  <TableCol
    {row}
    value="countryCode"
    header={$_('service-point.column.label.country-code')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.CountryCode)} />

  <TableCol
    {row}
    value="number"
    header={$_('service-point.column.label.number')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.Number)} />

  <TableCol
    {row}
    value="name"
    header={$_('service-point.column.label.name')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.Name)} />

  <TableCol
    {row}
    value="abbreviation"
    header={$_('service-point.column.label.name.short')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.Abbreviation)} />

  <TableCol {row} header={$_('service-point.column.label.vehicle-category')}>
    {#if row?.vehicleCategories}
      {row.vehicleCategories.join(', ')}
    {/if}
  </TableCol>

  <TableCol
    {row}
    value="businessOrganization"
    header={$_('service-point.column.label.organization.identifier')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.BusinessOrganization)} />

  <TableCol
    {row}
    value="businessOrganizationNumber"
    header={$_('service-point.column.label.organization.number')}
    sortable
    onHeaderClick={() => dispatchSortEvent(ServicePointSort.BusinessOrganizationNumber)} />

  <TableCol {row} value="validFrom" header={$_('service-point.column.label.validity')}>
    {formatValidityPeriod({
      start: row?.validFrom,
      end: row?.validTo
    })}
  </TableCol>
</Table>
