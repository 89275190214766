import { wrap } from 'src/api/query/fetchResult';
import { feedManagementApi } from 'src/api/apiClients';
export const fetchFeeds = (options) => {
    return wrap(() => feedManagementApi.getFeeds(options).then(f => f.feeds));
};
export const startFeed = (feedId) => {
    const func = () => feedManagementApi.startFeed({
        id: feedId
    });
    return wrap(func);
};
export const stopFeed = (feedId) => {
    const func = () => feedManagementApi.stopFeed({
        id: feedId
    });
    return wrap(func);
};
