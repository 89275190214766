import { get } from 'svelte/store';
import { replace } from 'svelte-spa-router';
import { wrap } from 'svelte-spa-router/wrap';
import NotFound from 'src/components/NotFound.svelte';
import { Permission } from 'src/user/permissions';
import { hasPermission } from 'src/user/permissionCheck';
import { internalUserStore, user } from 'src/user/userStore';
import MarketingList from 'src/components/pages/marketing/MarketingList.svelte';
import LineCategoriesList from 'src/components/pages/linecategories/LineCategoriesList.svelte';
import SchedulesList from 'src/components/pages/schedule/ScheduleList.svelte';
import AgencyList from 'src/components/pages/agency/AgencyList.svelte';
import StationList from 'src/components/pages/station/StationList.svelte';
import StationDetail from 'src/components/pages/station/detail/StationDetail.svelte';
import VdvDisruptionCausesList from 'src/components/pages/disruptionCausesVdv/DisruptionCausesList.svelte';
import LineColorList from 'src/components/pages/lineColor/LineColorList.svelte';
import LineTypeList from 'src/components/pages/lineType/LineTypeList.svelte';
import LineList from 'src/components/pages/line/LineList.svelte';
import TripList from 'src/components/pages/trips/TripList.svelte';
import TripByStation from 'src/components/pages/tripsByStation/TripByStation.svelte';
import MessageList from 'src/components/pages/message/MessageList.svelte';
import MessageDetails from 'src/components/pages/message/MessageDetails.svelte';
import TripDetails from 'src/components/pages/trips/TripDetails.svelte';
import { shouldShowVdvDisruptionCausePage } from 'src/components/pages/routesConditions';
import { routeLinks } from 'src/components/pages/routingService';
import FeedList from 'src/components/pages/feedManagement/FeedList.svelte';
import ServicePointList from 'src/components/pages/servicePoint/ServicePointList.svelte';
const requiresPermission = (permission) => {
    return () => {
        const $user = get(user);
        return hasPermission($user, permission);
    };
};
const lastRoute = `${routeLinks.schedule}*`;
const defaultRoute = {
    value: routeLinks.schedule,
    permission: Permission.SCHEDULE_READ
};
/**
 * Defines all possible routes in the HUB manager.
 * IMPORTANT: This definition must be kept in sync with the menu items specified in ManagerMenu.svelte.
 */
export const routes = {
    [`${routeLinks.schedule}*`]: wrap({
        component: SchedulesList,
        conditions: [requiresPermission(Permission.SCHEDULE_READ)]
    }),
    [`${routeLinks.agency}*`]: wrap({
        component: AgencyList,
        conditions: [requiresPermission(Permission.AGENCY_READ)]
    }),
    [`${routeLinks.line}*`]: wrap({
        component: LineList,
        conditions: [requiresPermission(Permission.LINE_READ)]
    }),
    [`${routeLinks.stationDetail}`]: wrap({
        component: StationDetail,
        conditions: [requiresPermission(Permission.STATION_READ)]
    }),
    [`${routeLinks.station}*`]: wrap({
        component: StationList,
        conditions: [requiresPermission(Permission.STATION_READ)]
    }),
    [`${routeLinks.tripsByStation}*`]: wrap({
        component: TripByStation,
        conditions: [requiresPermission(Permission.TRIP_READ)]
    }),
    [`${routeLinks.tripDetails}*`]: wrap({
        component: TripDetails,
        conditions: [requiresPermission(Permission.TRIP_READ)]
    }),
    [`${routeLinks.trip}*`]: wrap({
        component: TripList,
        conditions: [requiresPermission(Permission.TRIP_READ)]
    }),
    [`${routeLinks.messageDetails}*`]: wrap({
        component: MessageDetails,
        conditions: [requiresPermission(Permission.MESSAGE_READ)]
    }),
    [`${routeLinks.message}*`]: wrap({
        component: MessageList,
        conditions: [requiresPermission(Permission.MESSAGE_READ)]
    }),
    [`${routeLinks.marketing}*`]: wrap({
        component: MarketingList,
        conditions: [requiresPermission(Permission.MARKETING_READ)],
        userData: {
            nextRoute: routeLinks.schedule,
            permission: Permission.SCHEDULE_READ
        }
    }),
    [`${routeLinks.lineCategory}*`]: wrap({
        component: LineCategoriesList,
        conditions: [requiresPermission(Permission.LINE_CATEGORY_READ)],
        userData: {
            nextRoute: defaultRoute
        }
    }),
    [`${routeLinks.lineColor}*`]: wrap({
        component: LineColorList,
        conditions: [requiresPermission(Permission.LINE_COLOR_READ)],
        userData: {
            nextRoute: defaultRoute
        }
    }),
    [`${routeLinks.lineType}*`]: wrap({
        component: LineTypeList,
        conditions: [requiresPermission(Permission.LINE_TYPE_READ)],
        userData: {
            nextRoute: defaultRoute
        }
    }),
    [`${routeLinks.servicePoint}*`]: wrap({
        component: ServicePointList,
        conditions: [requiresPermission(Permission.SERVICE_POINT_READ)],
        userData: {
            nextRoute: defaultRoute
        }
    }),
    [`${routeLinks.vdvDisruptionCause}*`]: wrap({
        component: VdvDisruptionCausesList,
        conditions: [requiresPermission(Permission.DISRUPTION_CAUSE_READ), shouldShowVdvDisruptionCausePage],
        userData: {
            nextRoute: defaultRoute
        }
    }),
    [`${routeLinks.feedManagement}*`]: wrap({
        component: FeedList,
        conditions: [requiresPermission(Permission.FEED_MANAGEMENT)],
        userData: {
            nextRoute: defaultRoute
        }
    }),
    // Catch-all, must be last
    '*': NotFound
};
/**
 * Handles the case when access to a certain route is denied due to not having the necessary permission(s).
 */
export function handleConditionsFailed(event) {
    const user = get(internalUserStore);
    const detail = event.detail;
    if (detail.route === lastRoute && hasPermission(user, Permission.SCHEDULE_READ)) {
        return;
    }
    const userData = detail.userData;
    if ((userData === null || userData === void 0 ? void 0 : userData.nextRoute) && hasPermission(user, userData.nextRoute.permission)) {
        void replace(userData.nextRoute.value);
        return;
    }
    void replace('/not-found');
}
