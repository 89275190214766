import { wrapPaginated } from 'src/api/query/fetchResult';
import { configurationDataApi } from 'src/api/apiClients';
import { isNotBlank } from 'src/util/stringUtils';
import { applyStringParam } from 'src/api/paramUtils';
export const fetchMarketingTexts = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => configurationDataApi.findMarketingTexts(params, options);
    return wrapPaginated(func);
};
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    if (isNotBlank(filterCriteria.agency)) {
        request.agency = filterCriteria.agency;
    }
    applyStringParam({
        value: filterCriteria.text,
        target: request,
        eqField: 'textEq',
        likeField: 'textLike'
    });
    applyStringParam({
        value: filterCriteria.train,
        target: request,
        eqField: 'trainEq',
        likeField: 'trainLike'
    });
    return request;
};
