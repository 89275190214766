/**
 * When initializing a page if no page has been specified redirect to the default route
 * #state is to detect if keycloak redirected to this page.
 *
 * @param route the url to direct to.
 */
export const redirectToDefaultPage = (route) => {
    if (!window.location.hash || window.location.hash.startsWith('#state')) {
        window.location.hash = route;
    }
};
