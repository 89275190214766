import { __awaiter } from "tslib";
import { get, writable } from 'svelte/store';
import { AbortReason } from './fetchResult';
import { createFetchResult } from './fetchResult';
export const createQuery = (initialValue, supplyData, loadImmediately) => {
    const { subscribe, set } = writable(createFetchResult(initialValue));
    const loading = writable();
    let abortController = new AbortController();
    const next = () => __awaiter(void 0, void 0, void 0, function* () {
        if (get(loading)) {
            abortController.abort(new AbortReason(createFetchResult(initialValue)));
        }
        const currentAbortController = new AbortController();
        abortController = currentAbortController;
        loading.set(true);
        const result = yield supplyData(currentAbortController.signal);
        if (!currentAbortController.signal.aborted) {
            set(result);
            loading.set(false);
        }
    });
    if (loadImmediately) {
        next();
    }
    return {
        subscribe,
        next,
        loading,
        abort: () => abortController.abort(new AbortReason(createFetchResult(initialValue)))
    };
};
