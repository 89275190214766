import { __awaiter } from "tslib";
// ts-ignore
import license from '../../public/resources/license.json';
import { normalizeHttpUrl } from '@pids/shared-component';
import { licenseApi } from 'src/api/apiClients';
const licenses = license;
export const dependencies = licenses.map(s => ({
    name: s.name,
    version: s.installedVersion,
    license: s.licenseType,
    link: normalizeHttpUrl(s.link)
}));
export const fetchApiDependencies = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const dependencies = yield licenseApi.getLicenseInformation().then(response => response.data);
        return dependencies.map(dependency => ({
            name: dependency.name,
            version: dependency.version,
            license: dependency.license,
            link: dependency.link
        }));
    }
    catch (e) {
        console.error('Could not fetch license information');
    }
    return [];
});
