import { __awaiter } from "tslib";
import { cacheApi } from 'src/api/apiClients';
import { applyStringParam } from 'src/api/paramUtils';
import { wrapPaginated } from 'src/api/query';
import { writable } from 'svelte/store';
import { isNotBlank } from 'src/util/stringUtils';
export const fromTripsPage = writable(false);
export const fetchTrips = (criteria, pagination, options) => {
    const params = createRequestParams(criteria, pagination);
    return wrapPaginated(() => cacheApi.findTrips(params, options));
};
export const fetchTripFilterData = (schedule, options) => __awaiter(void 0, void 0, void 0, function* () {
    const req = {};
    if (isNotBlank(schedule)) {
        req.schedules = [schedule];
    }
    try {
        return yield cacheApi.getTripFilterData(req, options);
    }
    catch (e) {
        console.error('Failed to fetch filter data for trip', e);
        return Promise.resolve({
            agencies: []
        });
    }
});
export const fetchTripDetails = (id, schedule, options) => {
    return cacheApi.getTripDetails({ id, schedule }, options);
};
const createRequestParams = (criteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    if (criteria.schedule && isNotBlank(criteria.schedule.id)) {
        request.schedules = [criteria.schedule.id];
    }
    applyStringParam({ value: criteria.line, target: request, eqField: 'lineEq', likeField: 'lineLike' });
    applyStringParam({
        value: criteria.destination,
        target: request,
        eqField: 'destinationEq',
        likeField: 'destinationLike'
    });
    applyStringParam({
        value: criteria.agency,
        target: request,
        eqField: 'agencyEq',
        likeField: 'agencyLike'
    });
    applyStringParam({ value: criteria.id, target: request, eqField: 'idEq', likeField: 'idLike' });
    if (isNotBlank(criteria.stationId)) {
        request.stationId = criteria.stationId;
    }
    return request;
};
