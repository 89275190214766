<script lang="ts">
 import { _ } from 'svelte-i18n';
 import { DropdownSelect } from '@pids/shared-component';
  import { ScheduleType } from 'src/model/ScheduleType';

  const items: ScheduleType[] = [ScheduleType.Annual, ScheduleType.Daily];

  export let value: ScheduleType;

  const textKeys = {
   [ScheduleType.Annual]: 'trip.filter.schedule-type.annual.label',
   [ScheduleType.Daily]: 'trip.filter.schedule-type.daily.label'
  };

</script>

<DropdownSelect
  bind:value
  {items}
  readonly={true}
  showClearIcon={false}
  showChevron={true}
  on:change
  let:item>
 {$_(textKeys[item])}
</DropdownSelect>
