<script lang="ts">
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { StationFilterCriteria } from 'src/components/pages/station/stationService';
  import ScheduleSelect from 'src/components/shared/ScheduleSelect.svelte';
  import { QuickFilterType } from 'src/components/pages/routingService';

  export let filter: StationFilterCriteria;
</script>

<div class="columns">
  <div class="column">
    <label for="text-search">
      {$_('station.filter.label.id')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('station.filter.placeholder.id')}
        clearable
        bind:value={filter.id} />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="text-search">
      {$_('station.filter.label.sloid')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('placeholder.search')}
        bind:value={filter.sloid}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="text-search">
      {$_('station.filter.label.name')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('station.filter.placeholder.name')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="text-search">
      {$_('station.filter.label.name.short')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('station.filter.placeholder.name.short')}
        bind:value={filter.shortName}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="schedule-select">
      {$_('station.filter.schedule.label')}
    </label>
    <div class="control">
      <ScheduleSelect bind:value={filter.schedule} type={QuickFilterType.Station} id="schedule-select" />
    </div>
  </div>
</div>
