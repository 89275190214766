import { format, isEqual } from 'date-fns';
export const VALIDITY_DATE_FORMAT = 'dd.MM.yyyy';
export const formatValidityPeriod = (validityPeriod) => {
    if (!validityPeriod.start || !validityPeriod.end) {
        return '';
    }
    const formattedStartDate = format(validityPeriod.start, VALIDITY_DATE_FORMAT);
    if (isEqual(validityPeriod.start, validityPeriod.end)) {
        return formattedStartDate;
    }
    const formattedEndDate = format(validityPeriod.end, VALIDITY_DATE_FORMAT);
    return `${formattedStartDate} - ${formattedEndDate}`;
};
