/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import { TransportGroupFromJSON, TransportGroupFromJSONTyped, TransportGroupToJSON, } from './TransportGroup';
import { VehicleCategoryFromJSON, VehicleCategoryFromJSONTyped, VehicleCategoryToJSON, } from './VehicleCategory';
/**
 * Check if a given object implements the LineCategory interface.
 */
export function instanceOfLineCategory(value) {
    if (!('transportType' in value) || value['transportType'] === undefined)
        return false;
    if (!('category' in value) || value['category'] === undefined)
        return false;
    return true;
}
export function LineCategoryFromJSON(json) {
    return LineCategoryFromJSONTyped(json, false);
}
export function LineCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'transportType': json['transportType'],
        'category': VehicleCategoryFromJSON(json['category']),
        'transportGroup': json['transportGroup'] == null ? undefined : TransportGroupFromJSON(json['transportGroup']),
        'textDe': json['textDe'] == null ? undefined : json['textDe'],
        'textFr': json['textFr'] == null ? undefined : json['textFr'],
        'textIt': json['textIt'] == null ? undefined : json['textIt'],
        'textEn': json['textEn'] == null ? undefined : json['textEn'],
    };
}
export function LineCategoryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'transportType': value['transportType'],
        'category': VehicleCategoryToJSON(value['category']),
        'transportGroup': TransportGroupToJSON(value['transportGroup']),
        'textDe': value['textDe'],
        'textFr': value['textFr'],
        'textIt': value['textIt'],
        'textEn': value['textEn'],
    };
}
