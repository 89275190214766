/**
 * Checks whether the given text is blank or undefined/null.
 *
 * @param text the text to check
 */
export const isNotBlank = (text) => {
    return !isBlank(text);
};
/**
 * Checks whether the given text is empty, null/undefined or whitespace only.
 *
 * @param text the text to check
 */
export const isBlank = (text) => {
    return text === undefined || text === null || text.length === 0 || text.trim().length === 0;
};
/**
 * Returns the given text wrapped in double quotes.
 * Specifying an undefined or empty text returns an empty string.
 *
 * @param text the text to wrap
 */
export const quoted = (text) => {
    if (text && text.length) {
        return `"${text}"`;
    }
    return '';
};
