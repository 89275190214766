<script lang="ts">
  import FeedConfigTableList from 'src/components/pages/feedManagement/FeedConfigTableList.svelte';

  export let config: object;
</script>

<style>
  .container {
    display: grid;
    gap: 2rem;
  }
</style>

<div class="container">
  <FeedConfigTableList {config} />
</div>
