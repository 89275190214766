import { wrapPaginated } from 'src/api/query/fetchResult';
import { ScheduleProtocolEnum } from '$generated/service/cache-api';
import { cacheApi } from 'src/api/apiClients';
import { QuickFilterType } from 'src/components/pages/routingService';
export const fetchSchedules = (options) => {
    const func = () => cacheApi.findAllSchedules(options);
    return wrapPaginated(func);
};
export const scheduleQuickFilterOptions = {
    [ScheduleProtocolEnum.Hrdf]: [
        QuickFilterType.Trip,
        QuickFilterType.Line,
        QuickFilterType.Station,
        QuickFilterType.Agency
    ],
    [ScheduleProtocolEnum.GtfsRealtime]: [
        QuickFilterType.Trip,
        QuickFilterType.Line,
        QuickFilterType.Message,
        QuickFilterType.Station,
        QuickFilterType.Agency
    ],
    [ScheduleProtocolEnum.GtfsStatic]: [
        QuickFilterType.Trip,
        QuickFilterType.Line,
        QuickFilterType.Station,
        QuickFilterType.Agency
    ],
    [ScheduleProtocolEnum.Vdv454Aus]: [
        QuickFilterType.Trip,
        QuickFilterType.Line,
        QuickFilterType.Station,
        QuickFilterType.Agency
    ],
    [ScheduleProtocolEnum.Vdv736]: [QuickFilterType.Message]
};
export const schedulesByPageType = {
    [QuickFilterType.Agency]: [
        ScheduleProtocolEnum.Hrdf,
        ScheduleProtocolEnum.GtfsStatic,
        ScheduleProtocolEnum.GtfsRealtime,
        ScheduleProtocolEnum.Vdv454Aus
    ],
    [QuickFilterType.Station]: [
        ScheduleProtocolEnum.Hrdf,
        ScheduleProtocolEnum.GtfsStatic,
        ScheduleProtocolEnum.GtfsRealtime,
        ScheduleProtocolEnum.Vdv454Aus
    ],
    [QuickFilterType.Trip]: [
        ScheduleProtocolEnum.Hrdf,
        ScheduleProtocolEnum.GtfsStatic,
        ScheduleProtocolEnum.GtfsRealtime,
        ScheduleProtocolEnum.Vdv454Aus
    ],
    [QuickFilterType.Line]: [
        ScheduleProtocolEnum.Hrdf,
        ScheduleProtocolEnum.GtfsStatic,
        ScheduleProtocolEnum.GtfsRealtime,
        ScheduleProtocolEnum.Vdv454Aus
    ],
    [QuickFilterType.Message]: [ScheduleProtocolEnum.Vdv736, ScheduleProtocolEnum.GtfsRealtime]
};
