<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { NavbarLayout } from '@pids/shared-component';
  import { config } from 'src/config/static';
  import { userOrganization } from 'src/user/userStore';
  import NavbarLogin from 'src/components/layout/navbar/NavbarLogin.svelte';
</script>

<NavbarLayout environmentConfig={$config} pageTitle={$_(`navbar.title.${$userOrganization ?? ''}`)}>
  <svelte:fragment slot="left" />
  <svelte:fragment slot="right">
    <NavbarLogin />
  </svelte:fragment>
</NavbarLayout>
