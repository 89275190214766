<script lang="ts">
  import IC from './IC.svelte';
  import Number0 from './Number0.svelte';
  import Number1 from './Number1.svelte';
  import Number2 from './Number2.svelte';
  import Number3 from './Number3.svelte';
  import Number4 from './Number4.svelte';
  import Number5 from './Number5.svelte';
  import Number6 from './Number6.svelte';
  import Number7 from './Number7.svelte';
  import Number8 from './Number8.svelte';
  import Number9 from './Number9.svelte';
  import Number_0 from './Number_0.svelte';
  import Number_1 from './Number_1.svelte';
  import Number_2 from './Number_2.svelte';
  import Number_3 from './Number_3.svelte';
  import Number_4 from './Number_4.svelte';
  import Number_5 from './Number_5.svelte';
  import Number_6 from './Number_6.svelte';
  import Number_7 from './Number_7.svelte';
  import Number_8 from './Number_8.svelte';
  import Number_9 from './Number_9.svelte';
  import type { SvelteComponent } from 'svelte';

  export let number = '';
  export let height = '1em';

  const LeftNumberComponentMap: Record<string, typeof SvelteComponent> = {
    '0': Number0 as unknown as typeof SvelteComponent,
    '1': Number1 as unknown as typeof SvelteComponent,
    '2': Number2 as unknown as typeof SvelteComponent,
    '3': Number3 as unknown as typeof SvelteComponent,
    '4': Number4 as unknown as typeof SvelteComponent,
    '5': Number5 as unknown as typeof SvelteComponent,
    '6': Number6 as unknown as typeof SvelteComponent,
    '7': Number7 as unknown as typeof SvelteComponent,
    '8': Number8 as unknown as typeof SvelteComponent,
    '9': Number9 as unknown as typeof SvelteComponent
  } as const;

  const RightNumberComponentMap: Record<string, typeof SvelteComponent> = {
    '0': Number_0 as unknown as typeof SvelteComponent,
    '1': Number_1 as unknown as typeof SvelteComponent,
    '2': Number_2 as unknown as typeof SvelteComponent,
    '3': Number_3 as unknown as typeof SvelteComponent,
    '4': Number_4 as unknown as typeof SvelteComponent,
    '5': Number_5 as unknown as typeof SvelteComponent,
    '6': Number_6 as unknown as typeof SvelteComponent,
    '7': Number_7 as unknown as typeof SvelteComponent,
    '8': Number_8 as unknown as typeof SvelteComponent,
    '9': Number_9 as unknown as typeof SvelteComponent
  } as const;

  $: [leftNumber, rightNumber] = number.split('');

  $: leftNumberComponent = LeftNumberComponentMap[leftNumber];
  $: rightNumberComponent = RightNumberComponentMap[rightNumber];
</script>

<IC {height}>
  {#if leftNumberComponent}
    <svelte:component this={leftNumberComponent} />
  {/if}

  {#if rightNumberComponent}
    <svelte:component this={rightNumberComponent} />
  {/if}
</IC>
