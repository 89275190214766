<style lang="scss">
  @import 'src/styles/variables';

  .default-style-properties {
    --today-mark-size: 0.5rem;
    --today-mark-border-size: 0.0625rem;
  }

  .today-mark {
    width: var(--today-mark-size);
    height: var(--today-mark-size);
    background-color: $alert-error;
    border-radius: 50%;
    border: var(--today-mark-border-size) solid $white;
  }
</style>

<div class="today-mark default-style-properties" />
