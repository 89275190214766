/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.9
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TextSize = {
    Short: 'SHORT',
    Medium: 'MEDIUM',
    Long: 'LONG'
};
export function instanceOfTextSize(value) {
    for (const key in TextSize) {
        if (Object.prototype.hasOwnProperty.call(TextSize, key)) {
            if (TextSize[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function TextSizeFromJSON(json) {
    return TextSizeFromJSONTyped(json, false);
}
export function TextSizeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TextSizeToJSON(value) {
    return value;
}
