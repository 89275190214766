// available roles in Keycloak that can be assigned to a user
// needs to be in sync with the Keycloak instance used
export var Role;
(function (Role) {
    Role["NCA_ADMIN"] = "NCA_ADMIN";
    Role["NCA_USER"] = "NCA_USER";
    Role["NCA_READONLY_USER"] = "NCA_READONLY_USER";
    Role["OPERATOR_ADMIN"] = "OPERATOR_ADMIN";
    Role["OPERATOR_USER"] = "OPERATOR_USER";
    Role["OPERATOR_READONLY_USER"] = "OPERATOR_READONLY_USER";
})(Role || (Role = {}));
export var OrganizationRole;
(function (OrganizationRole) {
    OrganizationRole["DATA"] = "DATA";
    OrganizationRole["NONE"] = "NONE";
})(OrganizationRole || (OrganizationRole = {}));
