import { __awaiter, __rest } from "tslib";
import { KeycloakService } from 'src/user/keycloakService';
export class HttpResponseError extends Error {
    constructor(message, response) {
        super(message);
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: message
        });
        Object.defineProperty(this, "response", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: response
        });
        this.response = response;
    }
}
export class ClientError extends HttpResponseError {
}
export class AuthenticationError extends HttpResponseError {
}
export class AuthorizationError extends HttpResponseError {
}
export class ServerError extends HttpResponseError {
}
export const handleHttpError = (response) => __awaiter(void 0, void 0, void 0, function* () {
    if (response.ok) {
        return Promise.resolve(response);
    }
    switch (response.status) {
        case 401:
            KeycloakService.getKeycloakInstance().clearToken();
            return Promise.reject(new AuthenticationError('You are not authenticated to access the resource, please login.', response));
        case 403:
            return Promise.reject(new AuthorizationError("You don't have permissions to access the resource!", response));
        case 404:
            return Promise.reject(new ClientError('The request source could not be found', response));
        case 400:
            return handleClientError(response, e => Promise.reject(e));
    }
    if (response.status > 400 && response.status <= 451) {
        return Promise.reject(new ClientError('Client error occurred', response));
    }
    if (response.status >= 500 && response.status <= 599) {
        return Promise.reject(new ServerError('Server error occurred', response));
    }
    return Promise.reject(new HttpResponseError('Unhandled HTTP response error', response));
});
const handleClientError = (response, reject) => {
    if (!response.url.includes('cache/v1/')) {
        return reject(new ClientError('Client error occurred', response));
    }
    return response
        .json()
        .then((error) => {
        const { code, uiMessage } = error, details = __rest(error, ["code", "uiMessage"]);
        return reject(new HubCacheApiError(code, uiMessage, details));
    })
        .catch(() => reject(new ClientError('Client error occurred', response)));
};
export class HubCacheApiError extends Error {
    /**
     * Constructor.
     *
     * @param code the HUB error code
     * @param message the ui message
     * @param details additional details, depends on the type of error
     */
    constructor(code, message, details) {
        super(message);
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "details", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.code = code !== null && code !== void 0 ? code : 'UNKNOWN';
        this.details = details;
    }
}
