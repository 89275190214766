<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { createQuery } from 'src/api/query';
  import { fetchSchedules, schedulesByPageType } from 'src/components/pages/schedule/scheduleService';
  import type { SvelteEvent } from 'src/model/event';
  import type { ScheduleId } from 'src/model/ScheduleId';
  import { DropdownSelect } from '@pids/shared-component';
  import { type QuickFilterType } from 'src/components/pages/routingService';
  import type { Schedule } from 'src/generated/service/cache-api';

  /**
   * The id of the selected schedule.
   */
  export let value: ScheduleId | undefined = undefined;

  export let type: QuickFilterType | undefined = undefined;

  /**
   * The id to use for the select component.
   */
  export let id: string;

  const DEFAULT_SCHEDULE: ScheduleId = {
    id: ''
  };

  const dispatch = createEventDispatcher<{
    change: ScheduleId;
  }>();

  let items: ScheduleId[] = [];
  let selectedItem: ScheduleId;

  $: selectedItem = value ?? DEFAULT_SCHEDULE;

  const resultsQuery = createQuery<Schedule[]>([], () => fetchSchedules(), false);

  onMount(async () => {
    await resultsQuery.next();

    if ($resultsQuery.isError) {
      return;
    }
    const loadedResults = filterByType($resultsQuery.results);
    items = [DEFAULT_SCHEDULE, ...loadedResults];

    selectedItem = getInitialValue(value?.id, items);
  });

  const getInitialValue = (initialId: string | undefined, schedules: ScheduleId[]) => {
    const initialSchedule = schedules.find(schedule => schedule.id == initialId);
    return initialSchedule ?? DEFAULT_SCHEDULE;
  };

  const handleSelect = ({ detail: [selectedScheduleId] }: SvelteEvent<ScheduleId[]>) => {
    selectedItem = selectedScheduleId;

    if (selectedItem === DEFAULT_SCHEDULE && !value) {
      return;
    }

    value = selectedItem;
    dispatch('change', value);
  };

  const filter = (searchText: string, items: ScheduleId[]) =>
    items.filter(item => item.id.toLowerCase().includes(searchText.toLowerCase()));

  const filterByType = (schedules: Schedule[]) => {
    if (!type) {
      return schedules;
    }

    const availableSchedules = schedulesByPageType[type];
    return schedules.filter(schedule => schedule.protocol && availableSchedules.includes(schedule.protocol));
  };
</script>

<DropdownSelect
  value={selectedItem}
  {id}
  {items}
  {filter}
  readonly={true}
  showClearIcon={false}
  showChevron={true}
  on:change={handleSelect}
  let:item>
  {item.id === '' ? $_('select.all-items') : item.id}
</DropdownSelect>
