import { configurationDataApi } from 'src/api/apiClients';
import { wrapPaginated } from 'src/api/query/fetchResult';
export const fetchLineTypeMapping = (pagination, options) => {
    const params = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    return wrapPaginated(() => configurationDataApi.findLineTypeMapping(params, options));
};
