<script lang="ts">
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { AgencyFilterCriteria } from 'src/components/pages/agency/agencyService';
  import ScheduleSelect from 'src/components/shared/ScheduleSelect.svelte';
  import { QuickFilterType } from 'src/components/pages/routingService';

  export let filter: AgencyFilterCriteria;
</script>

<div class="columns">
  <div class="column is-one-quarter">
    <label for="text-search" style="visibility: hidden;">
      {$_('agency.filter.label.name')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('agency.filter.placeholder.name')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column is-one-quarter">
    <label for="schedule-select">
      {$_('agency.filter.schedule.label')}
    </label>
    <div class="control">
      <ScheduleSelect type={QuickFilterType.Agency} bind:value={filter.schedule} id="schedule-select" />
    </div>
  </div>
</div>
