<script lang="ts">
  import { DependencyModal, AboutModal, Button } from '@pids/shared-component';
  import { buildProperties } from 'src/config/buildProperties';
  import { fetchApiDependencies, dependencies as uiDependencies } from 'src/config/license';
  import { _ } from 'svelte-i18n';

  export let show = false;

  let showDependencyDialog = false;

  const openDependencyDialog = () => {
    showDependencyDialog = true;
  };

  const extractYear = (timestamp: string) => {
    return new Date(Date.parse(timestamp)).getFullYear();
  };
</script>

<AboutModal buttonText={$_('button.ok')} on:click={openDependencyDialog} bind:show>
  <svelte:fragment slot="pids-title">{$_('about.pids.title')}</svelte:fragment>
  <svelte:fragment slot="pids-version">{$_('about.pids.version')} {buildProperties.projectVersion}</svelte:fragment>

  <svelte:fragment slot="copyright">
    <div>
      <span style:display="inline">{$_('about.pids.license.link.prefix')}</span>
      <Button
        style="font-size: inherit; line-height: inherit; font-weight: bold;"
        on:click={openDependencyDialog}
        link={true}
        compact={true}>
        {$_('about.pids.license.link')}
      </Button>
    </div>
    <div>
      &copy; {extractYear(buildProperties.buildTimestamp)}
      {$_('about.pids.copyright1')}
    </div>
    <div>
      {$_('about.pids.copyright2')}
    </div>
  </svelte:fragment>
</AboutModal>

{#if showDependencyDialog}
  {#await fetchApiDependencies() then serverDependencies}
    <DependencyModal
      title={$_('about.pids.license.title').trim()}
      buttonText={$_('button.ok')}
      bind:show={showDependencyDialog}
      dependencySections={[
        { dependencies: uiDependencies, title: $_('about.pids.license.section.ui.title') },
        { dependencies: serverDependencies, title: $_('about.pids.license.section.server.title') }
      ]} />
  {/await}
{/if}
