<script lang="ts">
  const AccessibilityType = {
    WHEELCHAIR: 'wheelchair',
    WHEELCHAIR_PARTIALLY: 'wheelchair_partially',
    WHEELCHAIR_RESERVATION: 'wheelchair_reservation',
    WHEELCHAIR_SUBSTITUTE_TRANSPORT: 'wheelchair_substitute_transport',
    WHEELCHAIR_INACCESSIBLE: 'wheelchair_inaccessible',
    WHEELCHAIR_UNCERTAIN: 'wheelchair_uncertain'
  } as const;

  export let name: string;
  export let color = '#000000';
  export let height = '100%';
  export let style = '';
  export let alt = '';
</script>

{#if name === AccessibilityType.WHEELCHAIR}
  <svg
    version="1.0"
    {style}
    {height}
    aria-label={alt}
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 15 22"
    enable-background="new 0 0 15 22"
    xml:space="preserve">
    <path
      id="Vector"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 2C6.50006 1.96717 6.5066 1.93467 6.51922 1.90437C6.53184 1.87406 6.55031 1.84654 6.57358 1.82337C6.59684 1.8002 6.62443 1.78184 6.65479 1.76934C6.68515 1.75684 6.71767 1.75043 6.7505 1.7505C6.78333 1.75057 6.81583 1.7571 6.84613 1.76972C6.87644 1.78235 6.90396 1.80082 6.92713 1.82408C6.9503 1.84734 6.96866 1.87493 6.98116 1.90529C6.99366 1.93565 7.00006 1.96817 7 2.001C7 2.0673 6.97366 2.13089 6.92678 2.17778C6.87989 2.22466 6.8163 2.251 6.75 2.251C6.68369 2.251 6.62011 2.22466 6.57322 2.17778C6.52634 2.13089 6.5 2.0673 6.5 2.001V2ZM6.75 0.75C6.41835 0.75 6.10028 0.881749 5.86576 1.11626C5.63125 1.35078 5.4995 1.66885 5.4995 2.0005C5.4995 2.33215 5.63125 2.65022 5.86576 2.88474C6.10028 3.11925 6.41835 3.251 6.75 3.251C7.08152 3.251 7.39946 3.1193 7.63388 2.88488C7.8683 2.65046 8 2.33252 8 2.001C8 1.66948 7.8683 1.35154 7.63388 1.11712C7.39946 0.882696 7.08152 0.75 6.75 0.75ZM6.25 13.25V5H7.25V12.75H14.75V21.5H13.75V13.75H6.25V13.25ZM0.257999 14.75C0.257999 12.225 1.717 10.028 3.828 8.953L4.282 9.843C2.49 10.757 1.258 12.619 1.258 14.75C1.258 17.77 3.737 20.25 6.758 20.25C8.945 20.25 10.852 18.95 11.736 17.08L12.64 17.508C11.598 19.711 9.35 21.25 6.758 21.25C3.185 21.25 0.257999 18.323 0.257999 14.75Z"
      fill={color} />
  </svg>
{:else if name === AccessibilityType.WHEELCHAIR_PARTIALLY}
  <svg
    version="1.0"
    {style}
    {height}
    aria-label={alt}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 24"
    enable-background="new 0 0 30 24"
    xml:space="preserve">
    <path
      id="Vector"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.0999985 12C0.0999985 6.938 1.637 3.237 3.645 0.457001L4.455 1.042C2.564 3.662 1.1 7.161 1.1 12C1.1 16.832 2.56 20.187 4.463 22.967L3.638 23.532C1.64 20.615 0.0999985 17.067 0.0999985 12ZM6.253 15.746C6.253 13.223 7.713 11.024 9.823 9.95L10.277 10.841C8.485 11.753 7.253 13.616 7.253 15.746C7.253 18.77 9.733 21.246 12.753 21.246C14.941 21.246 16.847 19.948 17.731 18.078L18.635 18.505C17.593 20.709 15.345 22.246 12.753 22.246C9.18 22.246 6.253 19.322 6.253 15.746ZM29.601 12C29.601 6.932 28.061 3.385 26.062 0.467001L25.237 1.032C27.142 3.814 28.6 7.167 28.6 12C28.6 16.838 27.137 20.337 25.243 22.957L26.053 23.542C28.063 20.762 29.6 17.061 29.6 11.999L29.601 12ZM12.25 14.25V6H13.25V13.75H20.75V22.5H19.75V14.75H12.25V14.25ZM12.5 3C12.5 2.9337 12.5263 2.87011 12.5732 2.82322C12.6201 2.77634 12.6837 2.75 12.75 2.75C12.8163 2.75 12.8799 2.77634 12.9268 2.82322C12.9737 2.87011 13 2.9337 13 3C13 3.06631 12.9737 3.12989 12.9268 3.17678C12.8799 3.22366 12.8163 3.25 12.75 3.25C12.6837 3.25 12.6201 3.22366 12.5732 3.17678C12.5263 3.12989 12.5 3.06631 12.5 3ZM12.75 1.75C12.4185 1.75 12.1005 1.8817 11.8661 2.11612C11.6317 2.35054 11.5 2.66848 11.5 3C11.5 3.33152 11.6317 3.64946 11.8661 3.88388C12.1005 4.1183 12.4185 4.25 12.75 4.25C13.0815 4.25 13.3995 4.1183 13.6339 3.88388C13.8683 3.64946 14 3.33152 14 3C14 2.66848 13.8683 2.35054 13.6339 2.11612C13.3995 1.8817 13.0815 1.75 12.75 1.75Z"
      fill={color} />
  </svg>
{:else if name === AccessibilityType.WHEELCHAIR_RESERVATION}
  <svg
    version="1.0"
    {style}
    {height}
    aria-label={alt}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    enable-background="new 0 0 30 30"
    xml:space="preserve">
    <path
      id="Vector"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.75 0.25H0.25V29.75H29.75V0.25H0.75ZM1.25 28.75V1.25H28.75V28.75H1.25ZM9.828 12.952C7.717 14.028 6.258 16.225 6.258 18.75C6.258 22.323 9.185 25.25 12.758 25.25C15.349 25.25 17.598 23.711 18.64 21.508L17.736 21.08C16.851 22.95 14.945 24.25 12.758 24.25C9.737 24.25 7.258 21.77 7.258 18.75C7.258 16.619 8.49 14.757 10.282 13.843L9.828 12.953V12.952ZM12.25 17.25V9H13.25V16.75H20.75V25.5H19.75V17.75H12.25V17.25ZM12.5 6C12.5 5.9337 12.5263 5.87011 12.5732 5.82322C12.6201 5.77634 12.6837 5.75 12.75 5.75C12.8163 5.75 12.8799 5.77634 12.9268 5.82322C12.9737 5.87011 13 5.9337 13 6C13 6.0663 12.9737 6.12989 12.9268 6.17678C12.8799 6.22366 12.8163 6.25 12.75 6.25C12.6837 6.25 12.6201 6.22366 12.5732 6.17678C12.5263 6.12989 12.5 6.0663 12.5 6ZM12.75 4.75C12.4183 4.75 12.1003 4.88175 11.8658 5.11626C11.6312 5.35078 11.4995 5.66885 11.4995 6.0005C11.4995 6.33215 11.6312 6.65022 11.8658 6.88474C12.1003 7.11925 12.4183 7.251 12.75 7.251C13.0815 7.251 13.3995 7.1193 13.6339 6.88488C13.8683 6.65046 14 6.33252 14 6.001C14 5.66948 13.8683 5.35154 13.6339 5.11712C13.3995 4.8827 13.0815 4.75 12.75 4.75Z"
      fill={color} />
  </svg>
{:else if name === AccessibilityType.WHEELCHAIR_SUBSTITUTE_TRANSPORT}
  <svg
    version="1.0"
    {style}
    {height}
    aria-label={alt}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    enable-background="new 0 0 30 30"
    xml:space="preserve">
    <path
      id="Vector"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 0.25H29.75V29.75H0.25V0.25ZM1.25 1.25V28.75H28.75V1.25H1.25ZM9.25 18.75V12H10.25V18.25H16.25V25.5H15.25V19.25H9.25V18.75ZM4 19.8C4 17.665 5.155 15.83 6.993 14.995L7.407 15.905C5.945 16.57 5 18.035 5 19.8C5 22.224 7.026 24.25 9.45 24.25C11.182 24.25 12.68 23.183 13.511 21.66L14.389 22.14C13.419 23.917 11.618 25.25 9.45 25.25C6.474 25.25 4 22.776 4 19.8ZM9.5 9C9.5 8.9337 9.52634 8.87011 9.57322 8.82322C9.62011 8.77634 9.6837 8.75 9.75 8.75C9.8163 8.75 9.87989 8.77634 9.92678 8.82322C9.97366 8.87011 10 8.9337 10 9C10 9.0663 9.97366 9.12989 9.92678 9.17678C9.87989 9.22366 9.8163 9.25 9.75 9.25C9.6837 9.25 9.62011 9.22366 9.57322 9.17678C9.52634 9.12989 9.5 9.0663 9.5 9ZM9.75 7.75C9.41848 7.75 9.10054 7.8817 8.86612 8.11612C8.6317 8.35054 8.5 8.66848 8.5 9C8.5 9.33152 8.6317 9.64946 8.86612 9.88388C9.10054 10.1183 9.41848 10.25 9.75 10.25C10.0815 10.25 10.3995 10.1183 10.6339 9.88388C10.8683 9.64946 11 9.33152 11 9C11 8.66848 10.8683 8.35054 10.6339 8.11612C10.3995 7.8817 10.0815 7.75 9.75 7.75ZM17.294 5.309C17.663 4.94 18.171 4.75 18.734 4.75H23.234C24.443 4.75 25.234 5.757 25.234 6.75V7.632H25.697V7.882L25.699 9.382V9.632H25.235V13.25H25.233V13.994H22.733V13.25H19.234V14H16.734V9.632H16.281V9.382L16.282 7.882V7.632H16.735V6.75C16.735 6.186 16.925 5.678 17.294 5.309ZM18.001 6.016C17.845 6.172 17.735 6.414 17.735 6.75V9.25H24.235V6.75C24.235 6.243 23.827 5.75 23.235 5.75H18.735C18.398 5.75 18.157 5.86 18.001 6.016ZM24.235 10.25H17.735V12.25H24.235V10.25Z"
      fill={color} />
  </svg>
{:else if name === AccessibilityType.WHEELCHAIR_UNCERTAIN}
  <svg
    version="1.0"
    {style}
    {height}
    aria-label={alt}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 29 22"
    enable-background="new 0 0 29 22"
    xml:space="preserve">
    <path
      id="Vector"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.138 1.28999C22.791 0.479994 24.838 0.569994 26.507 1.38399L26.515 1.38799L26.523 1.39199C27.59 1.96199 28.529 2.99799 28.829 4.32399C29.135 5.67399 28.761 7.23099 27.373 8.78299L27.363 8.79299L27.353 8.80299L25.115 11.042C24.445 11.803 24.122 12.373 23.952 12.992C23.774 13.636 23.75 14.368 23.75 15.5H22.75C22.75 14.386 22.769 13.52 22.988 12.727C23.212 11.911 23.636 11.207 24.375 10.369L24.385 10.357L24.396 10.347L26.636 8.10599C27.844 6.75199 28.074 5.51799 27.854 4.54599C27.628 3.55099 26.912 2.73599 26.06 2.27799C24.636 1.58699 22.918 1.53099 21.579 2.18799C20.269 2.83099 19.25 4.18999 19.25 6.49999H18.25C18.25 3.84699 19.453 2.11799 21.138 1.28999ZM6.25 13.25V4.99999H7.25V12.75H14.75V21.5H13.75V13.75H6.25V13.25ZM0.25 14.751C0.25 12.226 1.709 10.028 3.82 8.95399L4.273 9.84399C2.482 10.756 1.25 12.62 1.25 14.751C1.25 17.772 3.727 20.251 6.75 20.251C8.937 20.251 10.843 18.951 11.726 17.081L12.63 17.509C11.59 19.713 9.341 21.251 6.75 21.251C3.175 21.251 0.25 18.324 0.25 14.751ZM22.817 18.497V21.497H23.817V18.497H22.817ZM6.5 1.99999C6.5 1.93369 6.52634 1.8701 6.57322 1.82322C6.62011 1.77633 6.6837 1.74999 6.75 1.74999C6.8163 1.74999 6.87989 1.77633 6.92678 1.82322C6.97366 1.8701 7 1.93369 7 1.99999C7 2.0663 6.97366 2.12989 6.92678 2.17677C6.87989 2.22366 6.8163 2.24999 6.75 2.24999C6.6837 2.24999 6.62011 2.22366 6.57322 2.17677C6.52634 2.12989 6.5 2.0663 6.5 1.99999ZM6.75 0.749994C6.41848 0.749994 6.10054 0.88169 5.86612 1.11611C5.6317 1.35053 5.5 1.66847 5.5 1.99999C5.5 2.33151 5.6317 2.64946 5.86612 2.88388C6.10054 3.1183 6.41848 3.24999 6.75 3.24999C7.08152 3.24999 7.39946 3.1183 7.63388 2.88388C7.8683 2.64946 8 2.33151 8 1.99999C8 1.66847 7.8683 1.35053 7.63388 1.11611C7.39946 0.88169 7.08152 0.749994 6.75 0.749994Z"
      fill={color} />
  </svg>
{:else if name === AccessibilityType.WHEELCHAIR_INACCESSIBLE}
  <svg
    version="1.0"
    {style}
    {height}
    aria-label={alt}
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 25"
    enable-background="new 0 0 20 25"
    xml:space="preserve">
    <path
      id="Vector"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.75 1.75C8.68356 1.75 8.61984 1.7764 8.57287 1.82337C8.52589 1.87035 8.4995 1.93407 8.4995 2.0005C8.4995 2.06694 8.52589 2.13066 8.57287 2.17763C8.61984 2.22461 8.68356 2.251 8.75 2.251C8.8163 2.251 8.87989 2.22466 8.92677 2.17778C8.97366 2.1309 9 2.06731 9 2.001C9 1.9347 8.97366 1.87111 8.92677 1.82423C8.87989 1.77734 8.8163 1.751 8.75 1.751V1.75ZM7.5 2C7.50006 1.83585 7.53246 1.67332 7.59534 1.52169C7.65822 1.37006 7.75035 1.23229 7.86647 1.11627C7.98259 1.00024 8.12042 0.90822 8.2721 0.845462C8.42379 0.782705 8.58634 0.750438 8.7505 0.750504C8.91465 0.750569 9.07718 0.782966 9.22881 0.845845C9.38045 0.908724 9.51821 1.00085 9.63423 1.11697C9.75026 1.23309 9.84228 1.37093 9.90504 1.52261C9.96779 1.67429 10.0001 1.83685 10 2.001C10 2.33252 9.8683 2.65047 9.63388 2.88489C9.39946 3.11931 9.08152 3.251 8.75 3.251C8.41848 3.251 8.10053 3.11931 7.86611 2.88489C7.63169 2.65047 7.5 2.33252 7.5 2.001V2ZM16.292 21.5L18.896 24.104L19.604 23.397L0.853996 4.647L0.145996 5.354L4.574 9.782C3.84745 10.3902 3.2629 11.1502 2.8614 12.0084C2.45991 12.8667 2.25122 13.8025 2.25 14.75C2.25 18.325 5.177 21.25 8.75 21.25C9.69761 21.2483 10.6333 21.0388 11.4912 20.6362C12.349 20.2336 13.1081 19.6478 13.715 18.92L15.751 20.957V21.499H16.293L16.292 21.5ZM13.003 18.211L5.284 10.491C4.6495 11.0052 4.13761 11.6544 3.78558 12.3913C3.43356 13.1282 3.25025 13.9343 3.249 14.751C3.249 17.773 5.728 20.251 8.749 20.251C10.459 20.251 11.991 19.454 13.003 18.211ZM16.25 12.75H13.25V13.75H15.75V16.25H16.75V12.75H16.25ZM9.25 5V8.75H8.25V5H9.25Z"
      fill={color} />
  </svg>
{/if}
