import { addDays, addMonths, addWeeks, addYears, endOfMonth, endOfWeek, endOfYear, isBefore, isSameMonth, isSameYear, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
export const expandYears = (start, end) => {
    const validityPeriod = { validFrom: start, validUntil: end };
    const years = [];
    for (let year = start; isBefore(year, end) || isSameYear(year, end); year = addYears(year, 1)) {
        years.push({
            current: year,
            months: expandMonths(isSameYear(year, start) ? start : startOfYear(year), isSameYear(year, end) ? end : endOfYear(year), validityPeriod)
        });
    }
    return years;
};
const expandMonths = (start, end, validityPeriod = {}) => {
    const { validFrom, validUntil } = validityPeriod;
    const months = [];
    for (let month = start; isBefore(month, end) || isSameMonth(month, end); month = addMonths(month, 1)) {
        months.push({
            current: month,
            validFrom: validFrom && isSameMonth(month, validFrom) ? validFrom : undefined,
            validUntil: validUntil && isSameMonth(month, validUntil) ? validUntil : undefined,
            weeks: expandWeeks(startOfMonth(month), endOfMonth(month))
        });
    }
    return months;
};
const expandWeeks = (fromWeek, toWeek) => {
    const weeks = [];
    const start = startOfWeek(fromWeek);
    const end = endOfWeek(toWeek);
    for (let week = start; isBefore(week, end); week = addWeeks(week, 1)) {
        weeks.push(expandDaysInWeek(week));
    }
    return weeks;
};
const expandDaysInWeek = (weekStart) => {
    const days = [];
    const weekEnd = endOfWeek(weekStart);
    for (let day = weekStart; isBefore(day, weekEnd); day = addDays(day, 1)) {
        days.push(day);
    }
    return days;
};
