<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { Button } from '@pids/shared-component';

  export let disabled: boolean;
</script>

<div>
  <Button on:click primary={true} {disabled} icon="filter_alt_off" className="is-pulled-right">
    {$_('filter.clear-all')}
  </Button>
</div>
