<script lang="ts">
  import { isPlainObject } from 'src/util/objectUtils';
  import FeedConfigTable from 'src/components/pages/feedManagement/FeedConfigTable.svelte';

  export let title: string | undefined = undefined;
  export let config: object;
</script>

<FeedConfigTable {title} {config} />

{#each Object.entries(config) as [key, value]}
  {#if isPlainObject(value)}
    <svelte:self title={key} config={value} />
  {/if}
{/each}
