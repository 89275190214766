import { isNotBlank } from 'src/util/stringUtils';
const isExactMatch = (text) => {
    return text.length > 2 && text.charAt(0) === '"' && text.charAt(text.length - 1) === '"';
};
const stripQuotes = (text) => {
    let result = text;
    if (result.charAt(0) === '"') {
        result = result.substring(1);
    }
    if (result.charAt(result.length - 1) === '"') {
        result = result.substring(0, result.length - 1);
    }
    return result;
};
/**
 * Assigns the text to the appropriate field of the request object based on whether it is an exact
 * match or a "like" search.
 *
 * @param params the parameters
 */
export const applyStringParam = (params) => {
    const { value, target, eqField, likeField } = params;
    if (isNotBlank(value)) {
        if (isExactMatch(value)) {
            const paramValue = stripQuotes(value);
            if (isNotBlank(paramValue)) {
                target[eqField] = paramValue;
            }
        }
        else {
            const paramValue = stripQuotes(value);
            if (isNotBlank(paramValue)) {
                target[likeField] = paramValue;
            }
        }
    }
};
