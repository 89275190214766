import { __awaiter } from "tslib";
import './styles/global.scss';
import Navbar from 'src/components/layout/Navbar.svelte';
import Sidebar from 'src/components/layout/Sidebar.svelte';
import PageContent from 'src/components/pages/PageContent.svelte';
import Notification from 'src/components/layout/Notification.svelte';
import { loadApplicationConfiguration } from 'src/config/static';
import { redirectToDefaultPage } from 'src/config/route';
import { authenticate } from 'src/user/authenticate';
import { setDefaultDateTimeOptions } from 'src/config/dateTimeConfig';
const loadUiComponents = () => {
    const navbar = new Navbar({
        target: document.querySelector('#navbar-container'),
        props: {}
    });
    const menu = new Sidebar({
        target: document.querySelector('#manager-left'),
        props: {}
    });
    const content = new PageContent({
        target: document.querySelector('#manager-right'),
        props: {}
    });
    const notifications = new Notification({
        target: document.querySelector('body'),
        props: {}
    });
    return {
        navbar,
        menu,
        content,
        notifications
    };
};
const loadPage = () => __awaiter(void 0, void 0, void 0, function* () {
    yield loadApplicationConfiguration();
    yield authenticate();
    setDefaultDateTimeOptions();
    redirectToDefaultPage('#/schedule/');
    loadUiComponents();
});
void loadPage().catch(e => console.error(e));
