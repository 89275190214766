<script lang="ts">
  export let height = '1em';
</script>

<svg {height} viewBox="0 0 59 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1.79193 0H57.2081C58.1967 0 59 0.806 59 1.8V18.2C59 19.194 58.1987 20 57.2081 20H1.79193C0.803277 20 0 19.194 0 18.2V1.8C0 0.806 0.803277 0 1.79193 0Z"
    fill="black" />
  <path
    d="M12.798 7.65898H10.3933C10.3458 7.09473 10.1349 6.66494 9.76047 6.36963C9.39133 6.06904 8.8719 5.91875 8.20217 5.91875C7.60627 5.91875 7.14221 6.0374 6.80999 6.27471C6.48303 6.50674 6.31956 6.83369 6.31956 7.25557C6.31956 7.63525 6.47776 7.90947 6.79417 8.07822C7.10002 8.24697 7.87522 8.48428 9.11975 8.79014C10.4328 9.11709 11.3425 9.44668 11.8488 9.77891C12.7822 10.3906 13.2489 11.2423 13.2489 12.3339C13.2489 13.531 12.7795 14.4749 11.8408 15.1657C11.0129 15.7722 9.89231 16.0754 8.47903 16.0754C6.90754 16.0754 5.68674 15.7168 4.81663 14.9996C3.94651 14.2824 3.49563 13.262 3.46399 11.9384H5.97942C6.01633 12.5817 6.18245 13.059 6.47776 13.3701C6.94182 13.8658 7.64055 14.1137 8.57395 14.1137C9.20676 14.1137 9.70774 13.9897 10.0769 13.7419C10.4935 13.4571 10.7018 13.0695 10.7018 12.5791C10.7018 12.0887 10.4091 11.7301 9.82376 11.5033C9.50208 11.3768 8.7427 11.1474 7.54563 10.8151C6.7968 10.6095 6.21145 10.4144 5.78958 10.2298C5.3677 10.0452 5.0302 9.83955 4.77708 9.61279C4.18645 9.08545 3.89114 8.35772 3.89114 7.42959C3.89114 6.3169 4.35256 5.43623 5.27542 4.7876C6.06116 4.23389 7.08157 3.95703 8.33665 3.95703C9.43879 3.95703 10.3616 4.17588 11.1052 4.61357C12.1757 5.24111 12.74 6.25625 12.798 7.65898Z"
    fill="#FFDE15" />
  <path
    d="M24.5129 4.23389V15.7985H21.9738L17.1327 7.92002L17.1644 15.7985H14.736V4.23389H17.3068L22.1161 12.0966L22.0845 4.23389H24.5129Z"
    fill="#FFDE15" />

  <slot />
</svg>
