<script lang="ts">
  import type { DisruptionCause } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { DisruptionCausesSort } from 'src/components/pages/disruptionCausesVdv/disruptionCausesSort';
  import { Table, TableCol } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';

  export let resultsList: DisruptionCause[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: DisruptionCausesSort };
  }>();

  const dispatchSortEvent = (sort: DisruptionCausesSort) => {
    dispatch('sort', { sort });
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('disruption-causes.column.category.header-label')}
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(DisruptionCausesSort.Category)}>
    {#if row?.category}
      {String(row.category).toUpperCase()}
    {/if}
  </TableCol>

  <TableCol
    {row}
    header={$_('disruption-causes.column.cause.header-label')}
    value="cause"
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(DisruptionCausesSort.Cause)} />

  <TableCol
    {row}
    header={$_('disruption-causes.column.group.header-label')}
    value="group"
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(DisruptionCausesSort.Group)}>
    {#if row?.group}
      {String(row.group).toLowerCase()}
    {/if}
  </TableCol>
</Table>
