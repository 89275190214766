<script lang="ts">
  export let height = '1em';
</script>

<svg {height} viewBox="0 0 59 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2 0C0.89543 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H57C58.1046 20 59 19.1046 59 18V2C59 0.895431 58.1046 0 57 0H2Z"
    fill="#EB0000" />
  <path d="M9.38753 4.19995H13.4301L8.42903 15.8H4.36975L9.38753 4.19995Z" fill="white" />
  <path
    d="M26.0917 12.3662C26.457 12.3662 26.7878 12.149 26.9352 11.8125L30.2164 4.19995H16.1289L11.1278 15.8H15.1526L18.9386 7.10955H24.9121L23.7148 9.88501L18.5064 9.86599L22.7091 15.8H27.2909L24.1775 12.3662L26.0917 12.3662Z"
    fill="white" />

  <slot />
</svg>
