export var ServicePointSort;
(function (ServicePointSort) {
    ServicePointSort["CountryCode"] = "countryCode";
    ServicePointSort["Number"] = "number";
    ServicePointSort["Sloid"] = "sloid";
    ServicePointSort["Name"] = "name";
    ServicePointSort["Abbreviation"] = "abbreviation";
    ServicePointSort["BusinessOrganization"] = "businessOrganization";
    ServicePointSort["BusinessOrganizationNumber"] = "businessOrganizationNumber";
})(ServicePointSort || (ServicePointSort = {}));
