<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ActionIcon, Button, notificationService, Table, TableCol } from '@pids/shared-component';
  import type { Station } from '$generated/service/cache-api';
  import { fetchStation, fromStationPage, stationParamNames } from '../stationService';
  import { selectedStation } from './stationDetailStore';
  import { pop, push, querystring } from 'svelte-spa-router';
  import { routeLinks } from 'src/components/pages/routingService';

  $: queryParams = new URLSearchParams($querystring);
  $: id = queryParams.get(stationParamNames.id);
  $: scheduleId = queryParams.get(stationParamNames.schedule);

  const abortController = new AbortController();
  export let params: Record<string, string> = {};

  let station: Station | undefined;

  onMount(() => {
    station = $selectedStation;

    if (!station) {
      fetchStationDetails();
    }
  });

  const fetchStationDetails = async () => {
    try {
      if (id && scheduleId) {
        station = await fetchStation(id, scheduleId, { signal: abortController.signal });
      }
    } catch (e) {
      notificationService.error($_('fetch.error.default'));
      console.error('Failed to fetch details for station', params.id, e);
    }
  };

  onDestroy(() => abortController.abort());
  const navigateBack = (): void => {
    if ($fromStationPage) {
      pop();
    } else {
      push(`#${routeLinks.station}`);
    }
  };

  $: queryParams, fetchStationDetails();
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .heading-title-text {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75;
    color: #212121;
  }

  .station-details-title {
    display: flex;
    gap: 0.5rem;

    line-height: $line-height-large;
  }

  .station-title {
    display: flex;
    gap: 0.625rem;
    padding-inline: 1rem;
  }

  .station-title-text {
    font-size: 1.5em;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
  }

  .station-details {
    display: grid;
    gap: 1.5rem;
  }

  .platforms {
    padding-block: 0.625rem 1.25rem;
    border-block: 1px solid black;
    box-sizing: border-box;
  }
</style>

<div class="station-details">
  <div class="station-details-title">
    <ActionIcon icon="arrow-back" on:click={navigateBack} />
    <div class="station-title">
      <span class="station-title-text">
        {station?.name}
      </span>
    </div>
  </div>

  <Table
    rows={[station]}
    let:row
    borderless={true}
    emptyText={$_('table.empty.default')}
    loadingText={$_('table.loading.default')}>
    <TableCol {row} value="id" header={$_('station.column.label.id')} />

    <TableCol {row} value="sloid" header={$_('station.column.label.sloid')} />

    <TableCol {row} value="name" header={$_('station.column.label.name')} />

    <TableCol {row} value="shortName" header={$_('station.column.label.name.short')} />

    <TableCol {row} header={$_('station.column.label.location')}>
      {#if row}
        {row.lat}&deg;N / {row.lon}&deg;E
      {/if}
    </TableCol>
    <TableCol {row} header={$_('station.column.schedule.label')}>
      {#if row}
        {row.schedule}
      {/if}
    </TableCol>
  </Table>

  <div class="platforms">
    <div class="my-4">
      <Table
        rows={station?.platforms ?? []}
        loading={false}
        let:row
        emptyText={$_('table.empty.default')}
        loadingText={$_('table.loading.default')}>
        <TableCol {row} value="id" header={$_('station.detail.platform.id')} />
        <TableCol {row} value="name" header={$_('station.detail.platform.name')} />
        <TableCol {row} value="sectors" header={$_('station.detail.platform.sectors')} />
        <TableCol
          {row}
          value="accessibilityPlatformAccess"
          header={$_('station.detail.platform.accessibility.access')} />
      </Table>
    </div>
  </div>

  <div>
    <Button icon="arrow-back" primary={true} outlined={true} on:click={navigateBack}>
      {$_('button.back')}
    </Button>
  </div>
</div>
