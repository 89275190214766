import { __awaiter } from "tslib";
import { wrapPaginated } from 'src/api/query/fetchResult';
import { configurationDataApi } from 'src/api/apiClients';
import { isNotBlank } from 'src/util/stringUtils';
import { applyStringParam } from 'src/api/paramUtils';
export const fetchLineColors = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => configurationDataApi.findLineColors(params, options);
    return wrapPaginated(func);
};
export const fetchLineColorAgencies = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield configurationDataApi.findLineColorAgencies();
});
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    if (isNotBlank(filterCriteria.agency)) {
        request.agency = filterCriteria.agency;
    }
    applyStringParam({
        value: filterCriteria.lineType,
        target: request,
        eqField: 'lineTypeEq',
        likeField: 'lineTypeLike'
    });
    applyStringParam({
        value: filterCriteria.lineName,
        target: request,
        eqField: 'lineNameEq',
        likeField: 'lineNameLike'
    });
    return request;
};
