<script lang="ts">
  import type { SvelteEvent } from 'src/model/event';
  import type { ScheduleId } from 'src/model/ScheduleId';
  import type { Pagination, Trip, TripFilter as TripFilterModel } from '$generated/service/cache-api';
  import type { PaginationParams, SortFunction } from 'src/api/query';
  import { type TripCriteria, TripSort, tripParamName } from './types';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { querystring, replace } from 'svelte-spa-router';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { createQuery, paginationParamName, sortBy } from 'src/api/query';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import { fetchTripFilterData, fetchTrips } from './tripService';
  import type { LoadingStateStore } from '@pids/shared-component';
  import { toTripsLink } from 'src/components/pages/routingService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import TripTable from './TripTable.svelte';
  import TripFilter from './TripFilter.svelte';

  const INITIAL_SORT = TripSort.StartTime;

  const sortFunction: SortFunction = sortBy(INITIAL_SORT);

  const abortController = new AbortController();

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${INITIAL_SORT},asc`
  };

  let paginationParams: PaginationParams = initialPagination;

  let tripCriteria = writable<TripCriteria>({});

  const resultsQuery = createQuery([], signal => fetchTrips($tripCriteria, paginationParams, { signal }), false);

  let firstLoad = true;

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($tripCriteria);

  let results: Trip[];
  $: results = $resultsQuery.isError ? [] : $resultsQuery.results;

  let pagination: Pagination | undefined;
  $: pagination = $resultsQuery.isError ? undefined : $resultsQuery.pagination;

  let filterData: TripFilterModel = {
    agencies: []
  };

  // keeps the latest loaded schedule to avoid unnecessary fetching on "clear filters"
  let filterDataSchedule: string | undefined = '_';

  let loading: LoadingStateStore;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscriberCriteria = tripCriteria.subscribe(() => {
      if (!firstLoad) {
        resetPageAndSort();
      } else {
        firstLoad = false;
      }
      void loadData();
    });

    void loadFilterData($tripCriteria.schedule?.id);
    return () => {
      unsubscriberCriteria();
      resultsQuery.abort();
    };
  });

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params);
    $tripCriteria = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): TripCriteria => {
    return {
      ...$tripCriteria,
      id: params.get(tripParamName.id) ?? undefined,
      line: params.get(tripParamName.line) ?? undefined,
      destination: params.get(tripParamName.destination) ?? undefined,
      agency: params.get(tripParamName.agency) ?? undefined,
      schedule: {
        id: params.get(tripParamName.schedule) ?? ''
      }
    };
  };

  const initializePaginationFromQueryParams = (params: URLSearchParams): PaginationParams => {
    return {
      page: params.get(paginationParamName.page) ?? paginationParams.page,
      size: params.get(paginationParamName.size) ?? paginationParams.size,
      sort: params.get(paginationParamName.sort) ?? paginationParams.sort
    } as PaginationParams;
  };

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const updateUrlParameters = () => {
    const fullUrl = toTripsLink({ ...$tripCriteria, schedule: $tripCriteria.schedule?.id }, paginationParams);
    replace(fullUrl);
  };

  const loadFilterData = async (scheduleId?: string | undefined) => {
    if (filterDataSchedule === scheduleId) {
      return;
    }

    filterDataSchedule = scheduleId;
    filterData = await fetchTripFilterData(scheduleId, { signal: abortController.signal });
  };

  const handleSortChange = (e: SvelteEvent<{ sort: TripSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };

    void loadData();
  };

  const filtersAreEmpty = (tripCriteria: TripCriteria): boolean => {
    return (
      !tripCriteria.id &&
      !tripCriteria.line &&
      !tripCriteria.destination &&
      !tripCriteria.agency &&
      !tripCriteria.schedule?.id
    );
  };

  const clearFilters = () => {
    $tripCriteria = {};
  };

  // NOTE: whenever the schedule changes, agencies must be cleared and reloaded
  // for the newly selected schedule
  const handleScheduleChange = (s: ScheduleId) => {
    loadFilterData(s?.id);

    $tripCriteria = {
      ...$tripCriteria,
      schedule: s,
      agency: undefined
    };
  };
</script>

<PageLayout title={$_('routes.group.core.trip')}>
  <svelte:fragment slot="criteria">
    <TripFilter bind:tripCriteria={$tripCriteria} {filterData} onScheduleChange={handleScheduleChange} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <TripTable {results} loading={$loading} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
