<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Table, TableCol, type LoadingState } from '@pids/shared-component';
  import type { LineTypeEntry } from '$generated/service/cache-api';
  import { LineTypeSort } from './lineTypeSort';

  export let resultsList: LineTypeEntry[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: LineTypeSort };
  }>();

  const dispatchSortEvent = (sort: LineTypeSort) => {
    dispatch('sort', { sort });
  };

</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}
>
  <TableCol {row} header={$_('line-type.column.key')} value="key" sortable width="15%"
            onHeaderClick={() => dispatchSortEvent(LineTypeSort.Key)} />
  <TableCol {row} header={$_('line-type.column.value')} value="value" sortable
            onHeaderClick={() => dispatchSortEvent(LineTypeSort.Value)} />
</Table>
