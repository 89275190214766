<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte';
  import { ActionIcon, Table, TableCol } from '@pids/shared-component';
  import { StationSort } from 'src/components/pages/station/stationSort';
  import type { Station } from 'src/generated/service/cache-api';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';
  import { selectedStation } from './detail/stationDetailStore';
  import { toStationDetailLink } from 'src/components/pages/routingService';
  import { fromStationPage } from 'src/components/pages/station/stationService';

  export let resultsList: Station[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: StationSort };
  }>();

  onMount(() => {
    if ($selectedStation) {
      $selectedStation = undefined;
    }
  });

  const dispatchSortEvent = (sort: StationSort) => {
    dispatch('sort', { sort });
  };

  const formatPlatforms = (station: Station) => {
    return station.platforms
      ?.flatMap(platform => {
        if (!platform.name) {
          return [];
        }

        if (platform.sectors?.length) {
          return platform.sectors.map(sector => platform.name + sector);
        }

        return [platform.name];
      })
      .join(', ');
  };

  const navigateToDetails = (station: Station): void => {
    $fromStationPage = true;
    selectedStation.set(station);
  };

  const toLink = (station: Station | undefined) => {
    if (!station) {
      return '#';
    }

    return toStationDetailLink({ id: station.id, schedule: station.schedule });
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    value="id"
    header={$_('station.column.label.id')}
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(StationSort.Id)} />

  <TableCol
    {row}
    value="sloid"
    header={$_('station.column.label.sloid')}
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(StationSort.Sloid)} />

  <TableCol
    {row}
    value="name"
    header={$_('station.column.label.name')}
    sortable
    onHeaderClick={() => dispatchSortEvent(StationSort.Name)} />

  <TableCol
    {row}
    value="shortName"
    header={$_('station.column.label.name.short')}
    sortable
    onHeaderClick={() => dispatchSortEvent(StationSort.ShortName)} />

  <TableCol {row} value="platforms" header={$_('station.column.label.platform')}>
    {row ? formatPlatforms(row) : ''}
  </TableCol>

  <TableCol {row} header={$_('station.column.label.location')}>
    {#if row?.lat && row?.lon}
      {row.lat}&deg;N / {row.lon}&deg;E
    {/if}
  </TableCol>

  <TableCol {row} value="schedule" header={$_('station.column.schedule.label')} width="20%" />

  <TableCol {row}>
    <ActionIcon href={toLink(row)} icon="search" on:click={() => row && navigateToDetails(row)} />
  </TableCol>
</Table>
