/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
/**
 * Check if a given object implements the Dependency interface.
 */
export function instanceOfDependency(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('version' in value) || value['version'] === undefined)
        return false;
    if (!('link' in value) || value['link'] === undefined)
        return false;
    if (!('license' in value) || value['license'] === undefined)
        return false;
    return true;
}
export function DependencyFromJSON(json) {
    return DependencyFromJSONTyped(json, false);
}
export function DependencyFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'version': json['version'],
        'link': json['link'],
        'license': json['license'],
    };
}
export function DependencyToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'version': value['version'],
        'link': value['link'],
        'license': value['license'],
    };
}
