<script lang="ts">
  import type { PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { LineColorFilterCriteria } from 'src/components/pages/lineColor/lineColorService';
  import type { LineColor, Pagination } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { LineColorSort } from 'src/components/pages/lineColor/lineColorSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import type { LoadingStateStore } from '@pids/shared-component';
  import { fetchLineColors } from 'src/components/pages/lineColor/lineColorService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import LineColorTable from 'src/components/pages/lineColor/LineColorTable.svelte';
  import LineColorFilter from 'src/components/pages/lineColor/LineColorFilter.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${LineColorSort.LineName},asc`
  };

  const sortFunction: SortFunction = sortBy(LineColorSort.LineName);
  let paginationParams: PaginationParams = initialPagination;

  let filter = writable<LineColorFilterCriteria>({});
  let results: LineColor[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  const resultsQuery = createQuery([], signal => fetchLineColors($filter, paginationParams, { signal }), false);

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    clearFilters();

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    await resultsQuery.next();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: LineColorSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const filtersAreEmpty = (filter: LineColorFilterCriteria): boolean => {
    return !filter.agency && !filter.lineName && !filter.lineType;
  };

  const clearFilters = () => {
    $filter = {};
  };
</script>

<PageLayout title={$_('line-color.page.title')}>
  <svelte:fragment slot="criteria">
    <LineColorFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <LineColorTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
