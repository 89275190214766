import { __awaiter } from "tslib";
import { KeycloakService } from './keycloakService';
import { config } from 'src/config/static';
import { login, updateToken } from './userStore';
let keycloakConfigUrl;
config.subscribe(cfg => {
    keycloakConfigUrl = cfg.keycloakConfigUrl;
});
export const authenticate = () => __awaiter(void 0, void 0, void 0, function* () {
    const onTokenRefreshed = (newToken) => {
        console.info('We should update the token: ', newToken);
        updateToken(newToken);
    };
    console.info('Initializing Keycloak from file: ', keycloakConfigUrl);
    yield KeycloakService.initKeycloakAndGetUser(keycloakConfigUrl, onTokenRefreshed)
        .then(loggedInUser => {
        console.debug('logged in user', loggedInUser);
        login(loggedInUser);
    })
        .catch((err) => {
        const errorMessage = 'Failed to initialize authentication and get user';
        console.error(errorMessage, err);
        alert(`${errorMessage}: ${err}`);
    });
});
