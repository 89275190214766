<script lang="ts">
  import { Table, TableCol } from '@pids/shared-component';
  import type { LoadingState } from '@pids/shared-component';
  import { type Link, toLink } from 'src/components/pages/routingService';
  import { scheduleQuickFilterOptions } from 'src/components/pages/schedule/scheduleService';
  import QuickFilters from 'src/components/shared/QuickFilters.svelte';
  import { ScheduleTypeEnum, ScheduleProtocolEnum, type Schedule } from 'src/generated/service/cache-api';
  import { formatValidityPeriod } from 'src/util/dateTimeUtils';
  import { _ } from 'svelte-i18n';

  export let resultsList: Schedule[];
  export let loading: LoadingState = undefined;

  const scheduleTypeTranslation: Record<ScheduleTypeEnum, string> = {
    [ScheduleTypeEnum.CompleteSchedule]: $_('schedule.type.complete'),
    [ScheduleTypeEnum.DailySchedule]: $_('schedule.type.daily')
  } as const;

  const scheduleProtocolTranslation: Record<ScheduleProtocolEnum, string> = {
    [ScheduleProtocolEnum.Hrdf]: $_('schedule.protocol.hrdf'),
    [ScheduleProtocolEnum.Vdv454Aus]: $_('schedule.protocol.vdv454aus'),
    [ScheduleProtocolEnum.Vdv736]: $_('schedule.protocol.vdv736'),
    [ScheduleProtocolEnum.GtfsStatic]: $_('schedule.protocol.gtfs-static'),
    [ScheduleProtocolEnum.GtfsRealtime]: $_('schedule.protocol.gtfs-realtime')
  } as const;

  const getScheduleLinks = (protocol: ScheduleProtocolEnum | undefined, schedule: string | undefined): Link[] => {
    if (!schedule || !protocol) {
      return [];
    }

    return scheduleQuickFilterOptions[protocol].map(type => ({
      type,
      url: toLink({
        type,
        data: {
          schedule
        }
      })
    }));
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol {row} value="id" header={$_('schedule.column.label.id')} className="w20" />

  <TableCol {row} className="w30" header={$_('schedule.column.label.agencies')}>
    {#if row?.agencies}
      {row.agencies.join(', ')}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('schedule.column.label.validity')}>
    {#if row?.validity}
      {formatValidityPeriod(row.validity)}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('schedule.column.label.type')}>
    {#if row?.type}
      {scheduleTypeTranslation[row.type]}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('schedule.column.label.format')}>
    {#if row?.protocol}
      {scheduleProtocolTranslation[row.protocol]}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('label.column.quick-filter')}>
    <QuickFilters links={getScheduleLinks(row?.protocol, row?.id)} />
  </TableCol>
</Table>
