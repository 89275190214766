<script lang="ts">
  export let height = '2.5rem';
</script>

<svg {height} viewBox="0 0 59 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M58 1H1V19H58V1Z" fill="white" />

  <path
    d="M57.2081 1C57.6466 1 58.0034 1.358 58.0034 1.8V18.2C58.0034 18.642 57.6466 19 57.2081 19H1.79193C1.35341 19 0.996622 18.642 0.996622 18.2V1.8C0.996622 1.358 1.35341 1 1.79193 1H57.2081ZM57.2081 0H1.79193C0.803277 0 0 0.806 0 1.8V18.2C0 19.194 0.803277 20 1.79193 20H57.2081C58.1987 20 59 19.194 59 18.2V1.8C59 0.806 58.1967 0 57.2081 0Z"
    fill="#EB0000" />
  <path
    d="M4.15991 4.23389H9.7603C10.8677 4.23389 11.7167 4.40791 12.3074 4.75596C12.7978 5.04072 13.1722 5.42041 13.4306 5.89502C13.689 6.36963 13.8182 6.90488 13.8182 7.50078C13.8182 8.17578 13.6547 8.76377 13.3278 9.26475C13.0061 9.76572 12.5552 10.1243 11.9751 10.3405C12.4867 10.4671 12.8875 10.7519 13.1775 11.1948C13.3621 11.4743 13.486 11.767 13.5493 12.0729C13.6125 12.3787 13.6943 13.0774 13.7945 14.169C13.8683 14.9706 13.9817 15.4689 14.1346 15.6641L14.2454 15.7985H11.6113C11.5216 15.6351 11.4557 15.4268 11.4135 15.1736C11.3766 14.9205 11.3265 14.3352 11.2632 13.4176C11.2 12.6002 10.9996 12.0359 10.6621 11.7248C10.3298 11.4084 9.7603 11.2502 8.95347 11.2502H6.68325V15.7985H4.15991V4.23389ZM6.68325 6.21934V9.39922H9.09585C9.87105 9.39922 10.4168 9.28848 10.7333 9.06699C11.134 8.7875 11.3344 8.36563 11.3344 7.80137C11.3344 7.25293 11.163 6.85215 10.8203 6.59902C10.4775 6.3459 9.93696 6.21934 9.19868 6.21934H6.68325Z"
    fill="#EB0000" />
  <path
    d="M24.6472 4.23389V6.36963H18.4931V8.8376H24.1331V10.823H18.4931V13.6628H24.758V15.7985H15.9698V4.23389H24.6472Z"
    fill="#EB0000" />

  <slot />
</svg>
