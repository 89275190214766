export const tripParamName = {
    id: 'id',
    line: 'line',
    destination: 'destination',
    agency: 'agency',
    schedule: 'schedule',
    station: 'station'
};
export const tripDetailsParamName = {
    id: 'id',
    schedule: 'schedule',
    stationId: 'stationId'
};
/**
 * The available sorting options for trips.
 */
export var TripSort;
(function (TripSort) {
    TripSort["Destination"] = "destination";
    TripSort["Line"] = "line";
    TripSort["StartTime"] = "startTime";
})(TripSort || (TripSort = {}));
