export const DEFAULT_USER_LOCALE = 'en';
export const DEFAULT_USER_ROLES = [];
export const DEFAULT_ORGANIZATION_ROLES = [];
export const DEFAULT_USER = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    locale: DEFAULT_USER_LOCALE,
    roles: DEFAULT_USER_ROLES,
    organizationRoles: DEFAULT_ORGANIZATION_ROLES,
    username: '',
    organization: '',
    token: 'no-user-logged-in'
};
