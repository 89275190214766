<script lang="ts">
  import type { MarketingTextFilterCriteria } from 'src/components/pages/marketing/marketingTextService';
  import { _ } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import { DebouncedTextInput, Icon, DropdownSelect, defaultStringIncludesFilter } from '@pids/shared-component';
  import { configurationDataApi } from 'src/api/apiClients';

  const abortController = new AbortController();
  export let filter: MarketingTextFilterCriteria;

  let agencies: string[];

  onMount(async () => {
    agencies = await configurationDataApi.findMarketingTextsAgencies({ signal: abortController.signal });
  });

  onDestroy(() => {
    abortController.abort();
  });
</script>

<div class="columns">
  <div class="column">
    <label for="text-search">
      {$_('marketing-text.filter.text.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('marketing-text.filter.text.placeholder')}
        bind:value={filter.text}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for="train-search">
      {$_('marketing-text.filter.train.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="train-search"
        class="input"
        placeholder={$_('marketing-text.filter.train.placeholder')}
        bind:value={filter.train}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    {#if agencies}
      <label for="agency-filter">
        {$_('marketing-text.filter.agency.label')}
      </label>
      <div class="control">
        <DropdownSelect
          id="agency-filter"
          items={agencies}
          bind:value={filter.agency}
          filter={defaultStringIncludesFilter}
          placeholder={$_('marketing-text.filter.agency.placeholder')}
          empty={$_('marketing-text.filter.agency.no-items-text')}
          readonly={Boolean(filter.agency)} />
      </div>
    {/if}
  </div>
</div>
