<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { Icon } from '@pids/shared-component';
  import { KeycloakService } from 'src/user/keycloakService';
  import { logout, userLoggedIn, user } from 'src/user/userStore';
  import AboutModal from './AboutModal.svelte';
  import ProfileInfo from './ProfileInfo.svelte';

  let showProfileModal = false;
  let showAboutModal = false;

  let userFullName: string;

  const onLogInClick = () => {
    KeycloakService.getKeycloakInstance()
      .login()
      .catch(e => console.error(e));
  };

  $: userFullName = $user.firstName + ' ' + $user.lastName;
</script>

{#if $userLoggedIn}
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link" title={userFullName}>
      <span class="icon is-small">
        <Icon name="account-outline" />
      </span>

      <span class="truncate-text" style="margin-left: 0.3rem; max-width: 150px;">
        {userFullName}
      </span>
    </a>
    <div class="navbar-dropdown">
      <a class="navbar-item" on:click={() => (showProfileModal = !showProfileModal)}>{$_('profile.title')}</a>
      <hr class="navbar-divider m-0" />
      <a class="navbar-item" on:click={() => (showAboutModal = !showAboutModal)}>{$_('button.about.dialog')}</a>
      <hr class="navbar-divider m-0" />
      <a class="navbar-item" on:click={logout}>{$_('button.logout')}</a>
    </div>
  </div>
{:else}
  <div class="navbar-item">
    <div class="buttons">
      <a class="button is-light" on:click={onLogInClick}>{$_('button.login')}</a>
    </div>
  </div>
{/if}

{#if showProfileModal}
  <ProfileInfo bind:show={showProfileModal} user={$user} />
{/if}

<AboutModal bind:show={showAboutModal} />
