<script lang="ts">
  import { ActionIcon } from '@pids/shared-component';
  import { QuickFilterType } from 'src/components/pages/routingService';
  import type { Link } from 'src/components/pages/routingService';

  const icon: Record<QuickFilterType, string> = {
    [QuickFilterType.Trip]: 'route',
    [QuickFilterType.Line]: 'looks_3',
    [QuickFilterType.Message]: 'info-message'
  } as const;

  export let links: Link[] = [];
</script>

<style>
  .quick-filters {
    display: flex;
    gap: 0.5rem;
  }
</style>

<div class="quick-filters">
  {#each links as ref}
    <ActionIcon icon={icon[ref.type]} href={ref.url} />
  {/each}
</div>
