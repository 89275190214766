import { __awaiter } from "tslib";
import { wrapPaginated } from 'src/api/query/fetchResult';
import { cacheApi } from 'src/api/apiClients';
import { applyStringParam } from 'src/api/paramUtils';
import { isNotBlank } from 'src/util/stringUtils';
import { writable } from 'svelte/store';
export const fromStationPage = writable(false);
export const stationParamNames = {
    id: 'id',
    sloid: 'sloid',
    name: 'name',
    shortName: 'shortName',
    schedule: 'schedule'
};
export const stationDetailsParamNames = {
    id: 'id',
    sloid: 'sloid',
    schedule: 'schedule'
};
export const fetchStations = (filter, pagination, options) => {
    const params = createFilterParams(filter, pagination);
    const func = () => cacheApi.findStations(params, options);
    return wrapPaginated(func);
};
const createFilterParams = (filterCriteria, pagination) => {
    const request = {};
    applyStringParam({ value: filterCriteria.id, target: request, eqField: 'idEq', likeField: 'idLike' });
    applyStringParam({ value: filterCriteria.sloid, target: request, eqField: 'sloidEq', likeField: 'sloidLike' });
    applyStringParam({ value: filterCriteria.name, target: request, eqField: 'nameEq', likeField: 'nameLike' });
    applyStringParam({
        value: filterCriteria.shortName,
        target: request,
        eqField: 'shortNameEq',
        likeField: 'shortNameLike'
    });
    if (filterCriteria.schedule && isNotBlank(filterCriteria.schedule.id)) {
        request.schedules = [filterCriteria.schedule.id];
    }
    request.page = pagination.page;
    request.size = pagination.size;
    request.sort = pagination.sort;
    return request;
};
export const fetchStation = (id, schedule, options) => __awaiter(void 0, void 0, void 0, function* () {
    const params = {
        schedules: [schedule],
        idEq: id
    };
    const response = yield wrapPaginated(() => cacheApi.findStations(params, options));
    if (response.isError) {
        return Promise.reject();
    }
    const stations = response.results;
    return stations.length > 0 ? stations[0] : Promise.reject();
});
