export const LineTypeIcon = {
    bex: 'bex',
    cnl: 'cnl',
    gex: 'gex',
    ext: 'ext',
    ice: 'ice',
    icn: 'icn',
    ogv: 'ogv',
    rjx: 'rjx',
    tgv: 'tgv',
    vae: 'vae',
    ec: 'ec',
    en: 'en',
    re: 're',
    pe: 'pe',
    sn: 'sn',
    rj: 'rj',
    rx: 'rx',
    ic: 'ic',
    ir: 'ir',
    nj: 'nj',
    ev: 'ev'
};
export const getLineIcon = (lineIcon) => {
    return LineTypeIcon[lineIcon.toLowerCase()];
};
