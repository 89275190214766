<script lang="ts">
  import type { LineColorFilterCriteria } from 'src/components/pages/lineColor/lineColorService';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon, DropdownSelect, defaultStringStartsWithFilter } from '@pids/shared-component';
  import { fetchLineColorAgencies } from 'src/components/pages/lineColor/lineColorService';

  export let filter: LineColorFilterCriteria;

  let agencies: string[];

  onMount(async () => {
    agencies = await fetchLineColorAgencies();
  });
</script>

<div class="columns">
  <div class="column">
    <label for="line-name-filter">
      {$_('line-color.filter.label.line-name')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="line-name-filter"
        class="input"
        placeholder={$_('line-color.filter.placeholder.line-name')}
        bind:value={filter.lineName}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="line-type-filter">
      {$_('line-color.filter.label.line-type')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="line-type-filter"
        class="input"
        placeholder={$_('line-color.filter.placeholder.line-type')}
        bind:value={filter.lineType}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    {#if agencies}
      <label for="agency-filter">
        {$_('line-color.filter.label.agency')}
      </label>
      <div class="control">
        <DropdownSelect
          id="agency-filter"
          bind:value={filter.agency}
          items={agencies}
          filter={defaultStringStartsWithFilter}
          placeholder={$_('line.filter.placeholder.agency')}
          empty={$_('line.filter.label.agency.empty')}
          readonly={Boolean(filter.agency)}
          showEmptyText={false} />
      </div>
    {/if}
  </div>
</div>
